import gql from 'graphql-tag';

export const getProductsOosStatus = gql`
  query getProductsOosStatus($productIds: [Int], $tenantId: String, $buyerId: String) {
    getProductsOosStatus(productIds: $productIds, tenantId: $tenantId, buyerId: $buyerId) {
      id
      is_oos
    }
  }
`;

export const getProductDetails = gql`
  query getProductDetails($productId: Int!, $tenantId: String, $buyerId: String) {
    getProductAttributesDescription(productId: $productId, tenantId: $tenantId) {
      attributes
    }
    getProductDetails(productId: $productId, tenantId: $tenantId, buyerId: $buyerId) {
      id
      name
      halal
      description
      is_single_variant
      tenant_id
      image
      date_quoted
      booking_order_id
      booking_order_date
      discoverability
      attributes
      tenant {
        tenant {
          name
          persons {
            phone
          }
        }
        email_notification
        class_id
        email
      }
      categories {
        main_categories {
          id
          name
        }
        sub_categories {
          name
        }
      }
      skusData {
        sku_id
        quotation_status
        limit_price
        is_favorite
        increment_qty
        specs
        weight
        unit_amount
        uom
        unit_per_item
        unit_per_item_uom
        unit_per_oom
        is_out_of_stock
        price
        priced_by
        is_order_by_weight
        is_catch_weight
        sku
        origin_price
        is_back_order
        total_stock_location
        total_available_quantity
        unit_price
        halal
        inven_id
        product_type_id
        quantity
        tax_rate
        oom
        direct_price
        is_special_price
        is_purchased
        display_direct_price
        display_price
        display_unit_price
        display_unit_price_per_weight
        attributes
        price_is_tax_inclusive
      }
      min_price
      max_price
      min_weight
      max_weight
      display_max_price
      display_min_price
      display_max_weight
      display_min_weight
      is_subscribed_to_notification
    }
  }
`;
export const subscribeToProductNotification = gql`
  mutation subscribeToProductNotification($productId: Int!) {
    subscribeToProductNotification(productId: $productId) {
      id
      user_id
      product_id
      active
    }
  }
`;
export const unSubscribeToProductNotification = gql`
  mutation unsubscribeToProductNotification($productId: Int!) {
    unsubscribeToProductNotification(productId: $productId) {
      id
      user_id
      product_id
      active
    }
  }
`;
