import gql from 'graphql-tag';

export const saleUpdateProfile = gql`
  mutation saleUpdateProfile(
    $firstName: String
    $lastName: String
    $gender: String!
    $image: String
    $birthDate: String
    $mobile: String
    $email: String
  ) {
    saleUpdateProfile(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      image: $image
      birthDate: $birthDate
      mobile: $mobile
      email: $email
    )
  }
`;
