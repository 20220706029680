import { DEFAULT_GETTERS } from '@/modules/sale/constants';

export default {
  ...DEFAULT_GETTERS,
  list: (state) => state.list,
  totalCount: (state) => state.totalCount,
  params: (state) => state.params,
  detailPurchase: (state) => state.detailPurchase?.list,
  customerAndStatus: (state) => state.customerAndStatus?.list,
  itemDisputes: (state) => state.itemDisputes?.list,
  invoiceList: (state) => state.invoiceList?.list,
  invoice: (state) => state.invoice?.list,
  sendInvoiceToPeppolByOrderId: (state) => state.sendInvoiceToPeppolByOrderId,
  item: (state) => state.item,
  isLoadMore: (state) => state.isLoadMore,
  customerPeppol: (state) => state.customerPeppol,
  tenantIntegrationStorecove: (state) => state.tenantIntegrationStorecove,
  viewPrice: (state) => state.viewPrice,
  list_customer_sale: (state) => state.list_customer_sale,
  totalCountCompany: (state) => state.totalCountCompany,
  isRepeatOrderSuccess: (state) => state.isRepeatOrderSuccess,
  unAvailableRepeatSkuIds: (state) => state.unAvailableRepeatSkuIds,
  isEnableSoftBlockOrder: (state) => state.isEnableSoftBlockOrderx
};
