import gql from 'graphql-tag';

export const repeatOrderItems = gql`
  mutation repeatOrderItems($orderId: Int!, $customerId: Int!) {
    repeatOrderItems(orderId: $orderId, customerId: $customerId) {
      unAvailableSkuIds
      success
    }
  }
`;

export const getOrderStatuses = gql`
  query getOrderStatuses {
    getOrderStatuses {
      id
      name
    }
  }
`;

export const getAvailableDeliverySlots = gql`
  query getAvailableDeliverySlots(
    $userActionDatetime: String!
    $tenantId: Int!
    $customerId: Int
    $items: [CheckDeliveryDateInput!]!
    $daysLimit: Int
    $isNewOrder: Boolean!
    $isAllowPassedCutOffTime: Boolean
  ) {
    getAvailableDeliverySlots(
      userActionDatetime: $userActionDatetime
      tenantId: $tenantId
      customerId: $customerId
      items: $items
      daysLimit: $daysLimit
      isNewOrder: $isNewOrder
      isAllowPassedCutOffTime: $isAllowPassedCutOffTime
    ) {
      date
      isAvailable
      noDeliveryReason
      slots {
        start
        end
        label
        leadHours
        isAvailable
        noDeliveryReason
        passedCutoffTime
      }
    }
  }
`;

export const getSupplierContactInfo = gql`
  query getSupplierContactInfo($tenantId: Int!, $customerId: Int!) {
    getSupplierContactInfo(tenantId: $tenantId, customerId: $customerId) {
      customerServiceNumber
      preferredCommunicationChannel
    }
  }
`;
