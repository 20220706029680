import { USER_TYPE_ID } from '@/modules/shared/constants';
import { isPlatform } from '@ionic/vue';

const isAllow = (currentUser) => {
  return (
    ([USER_TYPE_ID.BUYER, USER_TYPE_ID.SUB_BUYER].includes(currentUser.user_type_id) &&
      currentUser.buyer_type === 1) ||
    currentUser.user_type_id === USER_TYPE_ID.SALE ||
    currentUser.user_type_id === USER_TYPE_ID.SUPPLIER
  );
};

export const authGuard = async (to, from, next) => {
  // close browser if it's redirected from stripe and platform is android
  // workaround for stripe payment. Because android can't close opener window
  if (to.fullPath.indexOf('/b2b/payment-transaction/completed') > -1) {
    next();
  } else if (
    to.fullPath.indexOf('/b2b/payment-transaction/') > -1 &&
    isPlatform('android') &&
    to.fullPath.indexOf('close=true') > -1
  ) {
    next('/b2b/payment-transaction/completed');
  }
  const currentUser = await from.meta.$storage.getUser();
  const hasSkippedOnboarding = await from.meta.$storage.getSkippedOnBoarding();

  if (currentUser) {
    if (!isAllow) return next('/home');
    if (hasSkippedOnboarding) {
      if (
        [USER_TYPE_ID.BUYER, USER_TYPE_ID.SUB_BUYER].includes(currentUser.user_type_id) &&
        currentUser.buyer_type === 1 &&
        to.fullPath.indexOf('/b2b') === -1
      ) {
        next('/b2b');
      } else if (
        [USER_TYPE_ID.SALE, USER_TYPE_ID.ADMIN, USER_TYPE_ID.SUPPLIER].includes(currentUser.user_type_id) &&
        to.fullPath.indexOf('/sale') === -1
      ) {
        next('/sale');
      } else {
        next();
      }
    } else if (to.fullPath !== '/shared/auth/onboarding') {
      next('/shared/auth/onboarding');
    } else {
      next();
    }
  } else if (
    !currentUser &&
    to.fullPath.indexOf('/shared/auth') === -1 &&
    to.fullPath !== '/home' &&
    to.fullPath !== '/'
  ) {
    next('/home');
  } else {
    next();
  }
};
