import {
  DEFAULT_APP_LOGO,
  DEFAULT_NO_IMAGE,
  DEFAULT_PRODUCT_IMAGE,
  IMAGE_PATH
} from '@/modules/shared/constants/';

export const getImage = (imageName, imagepath, imageurl = process.env.VUE_APP_AWS_S3_IMAGE) => {
  if (imageName && imageName.includes('http')) return imageName;
  if (imagepath === DEFAULT_APP_LOGO || imageName === `APP_LOGO`) return DEFAULT_APP_LOGO;
  if (!imageName && imagepath === IMAGE_PATH.PRODUCT) return DEFAULT_PRODUCT_IMAGE;
  if (!imageName) return DEFAULT_NO_IMAGE;
  const defaultExtension = imagepath.indexOf('category-images') > -1 ? '.svg' : '.png';
  imageName = imageName.indexOf('.') > -1 ? imageName : `${imageName}${defaultExtension}`;
  const path = `${imageurl}/${imagepath}/${imageName}`;

  return path;
};
