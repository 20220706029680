<template>
  <ion-page>
    <ion-content>
      <div id="home-container">
        <div class="logo-container">
          <div class="logo">
            <ion-img src="/assets/images/splash_logo.png" alt="Treedots logo" />
          </div>
        </div>
        <div class="button-groups">
          <ion-button class="home-btn" expand="block" @click="loginMobile"
            >{{ $t('login_with_mobile') }}
          </ion-button>
          <ion-button class="home-btn" expand="block" fill="outline" @click="loginEmail"
            >{{ $t('login_with_email') }}
          </ion-button>
          <ion-label class="signup-link"
            >{{ $t('dont_have_an_account') }}
            <router-link to="/shared/auth/signup-info"
              ><strong>{{ $t('sign_up') }}</strong></router-link
            >
          </ion-label>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import branch from '@/services/shared/helper/branch';
import { IonButton, IonContent, IonImg, IonLabel, IonPage, onIonViewWillEnter } from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Home',
  components: {
    IonContent,
    IonPage,
    IonButton,
    IonLabel,
    IonImg
  },
  setup() {
    const router = useRouter();
    onIonViewWillEnter(() => {
      branch.logout();
    });
    const loginMobile = () => {
      router.push('/shared/auth/mobile');
    };
    const loginEmail = () => {
      router.push('/shared/auth/email');
    };
    return {
      loginEmail,
      loginMobile
    };
  }
});
</script>

<style scoped lang="scss">
$login-background: '/assets/images/home-bg.svg';
@import '@/views/Home/home.scss';

#home-container .logo-container {
  height: 65vh;
}
</style>
