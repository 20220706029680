import gql from 'graphql-tag';

export const getInvoicePaymentHistory = gql`
  query getInvoicePaymentHistory($invoiceId: Int!, $tenantId: Int!, $debitNoteId: Int) {
    getInvoicePaymentHistory(invoiceId: $invoiceId, tenantId: $tenantId, debitNoteId: $debitNoteId) {
      details {
        paymentNumber
        paymentDate
        paymentType
        type
        stripeCardId
        amount
      }
      paymentStatus
      paidAmount
      amount
      amountDue
      customerCreditUsed
      creditTerm
      display_amount
      display_paid_amount
      display_amount_due
    }
  }
`;

export const getDefaultCard = gql`
  query getDefaultCardByCustomer($customerId: Int!) {
    getDefaultCardByCustomer(customerId: $customerId) {
      ... on DefaultCardByCustomerResponse {
        cardId
        stripeCustomerId
      }
      ... on CardErrorResponse {
        type
        message
      }
    }
  }
`;

export const cancelManualPayment = gql`
  mutation cancelManualPayment($batchPaymentId: Int!) {
    cancelManualPayment(batchPaymentId: $batchPaymentId)
  }
`;

export const createTopUpCredit = gql`
  mutation createTopupCustomerCredit(
    $customerId: Int!
    $tenantId: Int!
    $transactionDate: String!
    $valueDate: String
    $paymentTypeId: Int!
    $paymentProof: [String]!
    $referenceNumber: String!
    $notes: String
    $totalPaidAmount: Float!
    $stripeCardId: String
    $stripeCustomerId: String
  ) {
    createTopupCustomerCredit(
      customerId: $customerId
      tenantId: $tenantId
      transactionDate: $transactionDate
      valueDate: $valueDate
      paymentTypeId: $paymentTypeId
      paymentProof: $paymentProof
      referenceNumber: $referenceNumber
      notes: $notes
      totalPaidAmount: $totalPaidAmount
      stripeCustomerId: $stripeCustomerId
      stripeCardId: $stripeCardId
    ) {
      redirectUrl
      payment_status
      details {
        id
        amount
        active
        reference_number
        payment_date
        payment_value_date
        status
        created_at
      }
    }
  }
`;
