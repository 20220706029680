import { apolloClient } from '@/main';
import { getProducts } from '@/modules/b2b/services/graphql';
import { getProductsOosStatus } from '@/modules/shared/services/graphql/product';
import debounce from 'lodash.debounce';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_MORE_PRODUCTS: 'GET_MORE_PRODUCTS',
  UPDATE_SEARCH: 'UPDATE_SEARCH'
};

const fetchProductData = async (productIds, commit, state) => {
  if (!productIds?.length) return;

  // Fetch more info to display OOS status, newest prices and more
  try {
    const { data } = await apolloClient.query({
      query: getProductsOosStatus,
      variables: {
        productIds: productIds,
        tenantId: state.search.tenantId,
        buyerId: state.search.buyerId
      }
    });

    // binding new data into old list
    commit(MUTATIONS.GET_PRODUCT_DETAILS_SUCCESS, data.getProductsOosStatus);
  } catch (e) {
    console.log(`fetchProductData ERROR: ${e.message}`);
  }
};

const fetchMoreProductData = async (productIds, commit, state) => {
  if (!productIds?.length) return;

  // Fetch more info to display OOS status, newest prices and more for more product section in new search page
  const { data } = await apolloClient.query({
    query: getProductsOosStatus,
    variables: {
      productIds: productIds,
      tenantId: state.search.tenantId,
      buyerId: state.search.buyerId
    }
  });

  // binding new data into old list
  commit(MUTATIONS.GET_MORE_PRODUCT_DETAILS_SUCCESS, data.getProductsOosStatus);
};

//load product list for general
const performSearch = async (commit, state) => {
  try {
    const { data, errors } = await apolloClient.query({
      query: getProducts,
      variables: state.search
    });
    if (!errors) {
      const newObj = {
        ...data.getProductsB2B
      };
      commit(MUTATIONS.GET_PRODUCTS_SUCCESS, { list: newObj, getProductStatus: 'Success' });
      // Fetch more info to display OOS status, newest prices and more in background
      const productIds = newObj.items.map((obj) => obj.id);
      fetchProductData(productIds, commit, state);
    } else {
      commit(MUTATIONS.GET_PRODUCTS_FAILURE, { getProductStatus: errors[0].message });
    }
  } catch (error) {
    commit(MUTATIONS.GET_PRODUCTS_FAILURE, { getProductStatus: error?.message });
  }
};

const performSearchDebounce = debounce(performSearch, 600);

// load product list only for new search page more product list

const performMoreProductSearch = async (commit, state) => {
  try {
    const { data, errors } = await apolloClient.query({
      query: getProducts,
      variables: state.searchMore
    });
    if (!errors) {
      const newObj = {
        ...data.getProductsB2B
      };

      commit(MUTATIONS.GET_MORE_PRODUCTS_SUCCESS, { moreProductList: newObj, getProductStatus: 'Success' });
      // Fetch more info to display OOS status, newest prices and more in background
      const productIds = newObj.items.map((obj) => obj.id);
      fetchMoreProductData(productIds, commit, state);
    } else {
      commit(MUTATIONS.GET_MORE_PRODUCTS_FAILURE, { getProductStatus: errors[0].message });
    }
  } catch (error) {
    commit(MUTATIONS.GET_MORE_PRODUCTS_FAILURE, { getProductStatus: error?.message });
  }
};

const performMoreProductSearchDebounce = debounce(performMoreProductSearch, 600);

export default {
  // get products
  async [ACTIONS.GET_PRODUCTS]({ commit, state }, { isLoadMore }) {
    commit(MUTATIONS.GET_PRODUCTS_REQUEST, isLoadMore);
    if (!isLoadMore) {
      performSearchDebounce(commit, state);
    } else {
      performSearch(commit, state);
    }
  },

  // get products for more product items in search page
  async [ACTIONS.GET_MORE_PRODUCTS]({ commit, state }, { isLoadMore }) {
    commit(MUTATIONS.GET_MORE_PRODUCTS_REQUEST, isLoadMore);
    if (!isLoadMore) {
      performMoreProductSearchDebounce(commit, state);
    } else {
      performMoreProductSearch(commit, state);
    }
  },

  // update search
  async [ACTIONS.UPDATE_SEARCH]({ commit }, { search = {}, isLoadMore = false, type }) {
    commit(MUTATIONS.UPDATE_SEARCH, { search, isLoadMore, type });
  }
};
