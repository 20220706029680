import gql from 'graphql-tag';

export const getPickupAddresses = gql`
  query getPickupAddresses($tenantId: Int!) {
    getPickupAddresses(tenantId: $tenantId) {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
      is_default
      country {
        id
        name
        description
      }
      asString1
      asString2
      is_pickup
    }
  }
`;

export const getTenantDeliveryOptions = gql`
  query getTenantDeliveryOptions($tenantId: Int!) {
    getTenantDeliveryOptions(tenantId: $tenantId)
  }
`;
