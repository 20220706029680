import { apolloClient } from '@/main';
import { saleAddOrder, saleCheckAllowDoubleOrder } from '@/modules/sale/services/graphql';
import { getMessage } from '@/services/shared/helper/services.js';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  ADD_ORDER: 'ADD_ORDER',
  CHECK_DOUBLE_ORDER: 'CHECK_DOUBLE_ORDER'
};

export default {
  // check duplicate ORDER
  async [ACTIONS.CHECK_DOUBLE_ORDER]({ commit }) {
    commit(MUTATIONS.CHECK_DOUBLE_ORDER_REQUEST);
    try {
      const { data } = await apolloClient.mutate({
        mutation: saleCheckAllowDoubleOrder
      });
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_SUCCESS);
      return data.saleCheckAllowDoubleOrder;
    } catch (error) {
      commit(MUTATIONS.CHECK_DOUBLE_ORDER_FAILURE, error);
    }
    return null;
  },

  // add ORDER
  async [ACTIONS.ADD_ORDER]({ commit, state }, params) {
    commit(MUTATIONS.ADD_ORDER_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: saleAddOrder,
        variables: params
      });
      if (!errors || errors.length === 0) {
        commit(MUTATIONS.ADD_ORDER_SUCCESS);
      } else {
        commit(MUTATIONS.ADD_ORDER_FAILURE, errors[0]);
        state.notTaggedItemsIds = errors[0].extensions?.skuIds;
      }
      return data;
    } catch (error) {
      commit(MUTATIONS.ADD_ORDER_FAILURE, getMessage(error));
    }
  }
};
