import gql from 'graphql-tag';

export const getPriceUpdateDetails = gql`
  query getPriceUpdateDetails(
    $skuId: Int!
    $subType: Int!
    $accountNumber: String
    $limit: Int
    $offset: Int
  ) {
    getPriceUpdateDetails(
      skuId: $skuId
      subType: $subType
      accountNumber: $accountNumber
      limit: $limit
      offset: $offset
    ) {
      sku {
        id
        name
        total_market_price
        unit_amount
        weight
        halal
        price
        uom_id
        direct_price
        priced_by
        is_catch_weight
        tax_rate
        is_order_by_weight
        specs
        oom_name
        uom_name
        unit_per_item
        unit_per_item_uom
        tenant_name
        image
        current_price
        previous_price
        product_id
        price_is_tax_inclusive
      }
      totalCount
      customers {
        id
        name
      }
    }
  }
`;

export const saleGetPriceUpdateDetails = gql`
  query saleGetPriceUpdateDetails(
    $skuId: Int!
    $subType: Int!
    $limitForDirectPrice: Int
    $offsetForDirectPrice: Int
    $limitForLimitPrice: Int
    $offsetForLimitPrice: Int
  ) {
    saleGetPriceUpdateDetails(
      skuId: $skuId
      subType: $subType
      limitForDirectPrice: $limitForDirectPrice
      offsetForDirectPrice: $offsetForDirectPrice
      limitForLimitPrice: $limitForLimitPrice
      offsetForLimitPrice: $offsetForLimitPrice
    ) {
      sku {
        id
        name
        total_market_price
        unit_amount
        current_price
        weight
        halal
        price
        uom_id
        tax_rate
        direct_price
        priced_by
        is_catch_weight
        is_order_by_weight
        specs
        oom_name
        uom_name
        unit_per_item
        unit_per_item_uom
        tenant_name
        image
        previous_direct_price
        quotation_limit_price
        direct_price
        product_id
        price_is_tax_inclusive
      }
      directPriceCustomers {
        totalCount
        data {
          id
          name
        }
      }
      limitPriceCustomers {
        totalCount
        data {
          id
          name
        }
      }
    }
  }
`;

export const getBadgeNumbers = gql`
  query getBadgeNumbers($customerId: Int!, $tenantId: Int!) {
    getBadgeNumbers(customerId: $customerId, tenantId: $tenantId) {
      carts
      notifications
    }
  }
`;
