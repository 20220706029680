import { apolloClient } from '@/main';
import {
  addStripeCard,
  createPaymentB2b,
  createPaymentChequeCash,
  createSaleAutomaticBatchPayments,
  getListCustomerCard,
  getPaymentHistoriesList,
  getPaymentHistoryByPaymentId,
  removeStripeCard,
  saleGetInvoicesByCustomer,
  saleGetIsFinanceModuleEnabled,
  saleGetTotalUnpaidInvoices,
  saleGetXeroCustomer,
  saleListStripeCard,
  setDefaultCard
} from '@/modules/sale/services/graphql';
import {
  DIGITAL_PAYMENT_METHOD,
  PAYMENT_HISTORY_LIST_STATUS as PAYMENT_STATUS
} from '@/modules/shared/constants';
import {
  cancelManualPayment,
  createTopUpCredit,
  getDefaultCard,
  getInvoicePaymentHistory
} from '@/modules/shared/services/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_LIST_CUSTOMER_CARD: 'GET_LIST_CUSTOMER_CARD',
  GET_DEFAULT_CARD: 'GET_DEFAULT_CARD',
  REMOVE_CARD: 'REMOVE_CARD',
  ADD_STRIPE_CARD: 'ADD_STRIPE_CARD ',
  SET_DEFAULT_CARD: 'SET_DEFAULT_CARD',
  SALE_LIST_STRIPE_CARD: 'SALE_LIST_STRIPE_CARD',
  GET_CUSTOMER_INVOICES: 'GET_CUSTOMER_INVOICES',
  LOAD_MORE_CUSTOMER_INVOICES: 'LOAD_MORE_CUSTOMER_INVOICES',
  RESET_STATUS_ERROR: 'RESET_STATUS_ERROR',
  GET_XERO_CUSTOMER: 'GET_XERO_CUSTOMER',
  GET_TOTAL_UNPAID_INVOICES: 'GET_TOTAL_UNPAID_INVOICES',
  CREATE_BANK_PAYMENT: 'CREATE_BANK_PAYMENT',
  CREATE_CASH_CHEQUE_PAYMENT: 'CREATE_CASH_CHEQUE_PAYMENT',
  SET_SELECTED_PAYMENT_INVOICES: 'SET_SELECTED_PAYMENT_INVOICES',
  REMOVE_SELECTED_PAYMENT_INVOICES: 'REMOVE_SELECTED_PAYMENT_INVOICES',
  SET_SELECTED_PAYMENT_METHOD: 'SET_SELECTED_PAYMENT_METHOD',
  REMOVE_SELECTED_PAYMENT_METHOD: 'REMOVE_SELECTED_PAYMENT_METHOD',
  CHECK_IS_FINANCE_MODULE_ENABLED: 'CHECK_IS_FINANCE_MODULE_ENABLED',
  CREATE_AUTOMATIC_BATCH_PAYMENTS: 'CREATE_AUTOMATIC_BATCH_PAYMENTS',
  GET_PAYMENT_HISTORY: 'GET_PAYMENT_HISTORY',
  GET_PAYMENT_HISTORY_LIST: 'GET_PAYMENT_HISTORY_LIST',
  LOAD_MORE_PAYMENT_HISTORY_LIST: 'LOAD_MORE_PAYMENT_HISTORY_LIST',
  CANCEL_MANUAL_PAYMENT: 'CANCEL_MANUAL_PAYMENT',
  SET_PARAMS_TOP_UP_CREDIT: 'SET_PARAMS_TOP_UP_CREDIT',
  CREATE_TOP_UP_CREDIT: 'CREATE_TOP_UP_CREDIT',
  RESET_PARAMS_TOP_UP_CREDIT: 'RESET_PARAMS_TOP_UP_CREDIT',
  SET_ENTRY_TOP_UP_CREDIT: 'SET_ENTRY_TOP_UP_CREDIT',
  GET_PAYMENT_INVOICE_DETAILS: 'GET_PAYMENT_INVOICE_DETAILS'
};

export default {
  // get list customer card
  async [ACTIONS.GET_LIST_CUSTOMER_CARD]({ commit }) {
    commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getListCustomerCard
      });
      commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_SUCCESS, data.listCardByCustomerId);
    } catch (error) {
      commit(MUTATIONS.GET_LIST_CUSTOMER_CARD_FAILURE, error);
    }
  },

  // get default card
  async [ACTIONS.GET_DEFAULT_CARD]({ commit }, customerId) {
    commit(MUTATIONS.GET_DEFAULT_CARD_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getDefaultCard,
        variables: {
          customerId
        }
      });
      if (!errors) {
        const cardId = data.getDefaultCardByCustomer?.cardId ?? null;
        commit(MUTATIONS.GET_DEFAULT_CARD_SUCCESS, cardId);
      } else {
        commit(MUTATIONS.GET_DEFAULT_CARD_FAILURE, errors[0]);
      }
    } catch (error) {
      if (error instanceof Error) commit(MUTATIONS.GET_DEFAULT_CARD_FAILURE, error);
      throw new Error(error);
    }
  },

  // remove card
  async [ACTIONS.REMOVE_CARD]({ commit, dispatch }, cardId) {
    commit(MUTATIONS.REMOVE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: removeStripeCard,
        variables: {
          cardId
        }
      });
      if (data.removeCardByCustomer.error) {
        commit(MUTATIONS.REMOVE_CARD_FAILURE, data.removeCardByCustomer);
      } else {
        commit(MUTATIONS.REMOVE_CARD_SUCCESS, data.removeCardByCustomer);
        dispatch(ACTIONS.GET_LIST_CUSTOMER_CARD);
        dispatch(ACTIONS.GET_DEFAULT_CARD);
      }
    } catch (error) {
      commit(MUTATIONS.REMOVE_CARD_FAILURE, error);
    }
  },

  // add stripe card
  async [ACTIONS.ADD_STRIPE_CARD]({ commit, dispatch }, card) {
    commit(MUTATIONS.ADD_STRIPE_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: addStripeCard,
        variables: card
      });
      if (data.addCardByCustomer.error) {
        commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, data.addCardByCustomer);
      } else {
        commit(MUTATIONS.ADD_STRIPE_CARD_SUCCESS, data.addCardByCustomer);
        dispatch(ACTIONS.GET_LIST_CUSTOMER_CARD);
        dispatch(ACTIONS.GET_DEFAULT_CARD);
      }
    } catch (error) {
      commit(MUTATIONS.ADD_STRIPE_CARD_FAILURE, error);
    }
  },

  // set default card
  async [ACTIONS.SET_DEFAULT_CARD]({ commit }, cardId) {
    commit(MUTATIONS.SET_DEFAULT_CARD_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: setDefaultCard,
        variables: {
          cardId
        }
      });
      if (data.setDefaultCardByCustomer.error) {
        commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, data.setDefaultCardByCustomer);
      } else {
        commit(MUTATIONS.SET_DEFAULT_CARD_SUCCESS, data.setDefaultCardByCustomer.cardId);
      }
    } catch (error) {
      commit(MUTATIONS.SET_DEFAULT_CARD_FAILURE, error);
    }
  },
  async [ACTIONS.SALE_LIST_STRIPE_CARD]({ commit }, params) {
    try {
      const { data, errors } = await apolloClient.query({
        query: saleListStripeCard,
        variables: {
          customerId: params
        }
      });
      if (!errors) {
        commit(MUTATIONS.SALE_LIST_STRIPE_CARD_SUCCESS, data.saleListCardByCustomerId);
      } else {
        commit(MUTATIONS.SALE_LIST_STRIPE_CARD_FAILURE, errors[0]);
      }
    } catch (error) {
      if (error instanceof Error) commit(MUTATIONS.SALE_LIST_STRIPE_CARD_FAILURE, error);
      throw new Error(error);
    }
  },
  async [ACTIONS.GET_CUSTOMER_INVOICES](
    { commit, state },
    { paramsListInvoices, isLoadMore = false, onFinish }
  ) {
    commit(MUTATIONS.GET_CUSTOMER_INVOICES_REQUEST, isLoadMore);
    try {
      const { data, errors } = await apolloClient.query({
        query: saleGetInvoicesByCustomer,
        variables: {
          ...state.paramsListInvoices,
          ...paramsListInvoices
        }
      });
      if (!errors) {
        if (state.paramsListInvoices.isIncludeTotalCount)
          state.totalCountInvoices = data.saleGetInvoicesByCustomer.totalCount;
        if (onFinish) onFinish();
        commit(MUTATIONS.GET_CUSTOMER_INVOICES_SUCCESS, { listInvoices: data.saleGetInvoicesByCustomer });
      } else {
        commit(MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_CUSTOMER_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.LOAD_MORE_CUSTOMER_INVOICES](
    { commit, dispatch },
    { paramsListInvoices, isLoadMore = true, onFinish }
  ) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_GET_CUSTOMER_INVOICE, { paramsListInvoices });
    dispatch(ACTIONS.GET_CUSTOMER_INVOICES, { isLoadMore, onFinish });
  },
  async [ACTIONS.RESET_STATUS_ERROR]({ commit }) {
    commit(MUTATIONS.RESET_STATUS_ERROR);
  },
  async [ACTIONS.GET_XERO_CUSTOMER]({ commit }, params) {
    try {
      const { data, errors } = await apolloClient.query({
        query: saleGetXeroCustomer,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_XERO_CUSTOMER_SUCCESS, data.saleGetXeroCustomer);
      } else {
        commit(MUTATIONS.GET_XERO_CUSTOMER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_XERO_CUSTOMER_FAILURE, error);
    }
  },
  async [ACTIONS.GET_TOTAL_UNPAID_INVOICES]({ commit }, params) {
    try {
      const { data, errors } = await apolloClient.query({
        query: saleGetTotalUnpaidInvoices,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_SUCCESS, data.saleGetTotalUnpaidInvoices);
      } else {
        commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_TOTAL_UNPAID_INVOICES_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS]({ commit }, { paramsCreatePayment }) {
    commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_REQUEST, paramsCreatePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createSaleAutomaticBatchPayments,
        variables: {
          ...paramsCreatePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_SUCCESS, {
          paymentSummary: data.createSaleAutomaticBatchPayments
        });
      } else {
        commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_AUTOMATIC_BATCH_PAYMENTS_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_BANK_PAYMENT]({ commit }, { paramsCreateBankPayment }) {
    commit(MUTATIONS.CREATE_BANK_PAYMENT_REQUEST, paramsCreateBankPayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentB2b,
        variables: {
          ...paramsCreateBankPayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentB2b });
      } else {
        commit(MUTATIONS.CREATE_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_PAYMENT_FAILURE, error);
    }
  },
  async [ACTIONS.CREATE_CASH_CHEQUE_PAYMENT]({ commit }, { paramsCreateCashChequePayment }) {
    commit(MUTATIONS.CREATE_CASH_CHEQUE_PAYMENT_REQUEST, paramsCreateCashChequePayment);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createPaymentChequeCash,
        variables: {
          ...paramsCreateCashChequePayment
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_PAYMENT_SUCCESS, { paymentSummary: data.createPaymentChequeCash });
      } else {
        commit(MUTATIONS.CREATE_PAYMENT_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_PAYMENT_FAILURE, error);
    }
  },
  async [ACTIONS.SET_SELECTED_PAYMENT_INVOICES]({ commit }, data) {
    commit(MUTATIONS.SET_SELECTED_PAYMENT_INVOICES, data);
  },
  async [ACTIONS.REMOVE_SELECTED_PAYMENT_INVOICES]({ commit }) {
    commit(MUTATIONS.REMOVE_SELECTED_PAYMENT_INVOICES);
  },
  async [ACTIONS.SET_SELECTED_PAYMENT_METHOD]({ commit }, data) {
    commit(MUTATIONS.SET_SELECTED_PAYMENT_METHOD, data);
  },
  async [ACTIONS.REMOVE_SELECTED_PAYMENT_METHOD]({ commit }) {
    commit(MUTATIONS.REMOVE_SELECTED_PAYMENT_METHOD);
  },
  async [ACTIONS.CHECK_IS_FINANCE_MODULE_ENABLED]({ commit }, params) {
    try {
      const { data } = await apolloClient.query({
        query: saleGetIsFinanceModuleEnabled,
        variables: {
          customerId: params.customerId,
          tenantId: params.tenantId
        }
      });
      commit(MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED_SUCCESS, data.saleGetIsFinanceModuleEnabled);
    } catch (error) {
      commit(MUTATIONS.CHECK_IS_FINANCE_MODULE_ENABLED_FAILURE, error);
    }
  },

  async [ACTIONS.GET_PAYMENT_HISTORY]({ commit }, params) {
    commit(MUTATIONS.GET_PAYMENT_HISTORY_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getInvoicePaymentHistory,
        variables: {
          tenantId: params.tenantId,
          invoiceId: params.invoiceId,
          debitNoteId: params.debitNoteId
        }
      });
      if (!errors) {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_SUCCESS, {
          paymentHistory: data.getInvoicePaymentHistory
        });
      } else {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PAYMENT_HISTORY_FAILED, error);
    }
  },

  async [ACTIONS.GET_PAYMENT_HISTORY_LIST](
    { commit, state },
    { paramsPaymentHistoryList, isLoadMore = false, onFinish }
  ) {
    commit(MUTATIONS.GET_PAYMENT_HISTORY_LIST_REQUEST, isLoadMore);
    try {
      const { data, errors } = await apolloClient.query({
        query: getPaymentHistoriesList,
        variables: {
          ...state.paramsPaymentHistoryList,
          ...paramsPaymentHistoryList
        }
      });
      if (!errors) {
        if (onFinish) onFinish();
        // TEMPORARY LOGIC, WILL BE REMOVED AFTER FLUID IS ENABLED.
        const paymentHistoryList = data.getPaymentHistoryCustomer.reduce((acc, paymentItem) => {
          if (
            !(
              paymentItem.payment_method === DIGITAL_PAYMENT_METHOD.FLUID &&
              paymentItem.payment_status === PAYMENT_STATUS.REJECTED
            )
          ) {
            acc.push(paymentItem);
          }
          return acc;
        }, []);
        commit(MUTATIONS.GET_PAYMENT_HISTORY_LIST_SUCCESS, { paymentHistoryList });
      } else {
        commit(MUTATIONS.GET_PAYMENT_HISTORY_LIST_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PAYMENT_HISTORY_LIST_FAILED, error);
    }
  },

  async [ACTIONS.LOAD_MORE_PAYMENT_HISTORY_LIST](
    { commit, dispatch },
    { paramsPaymentHistoryList, isLoadMore = true, onFinish }
  ) {
    commit(MUTATIONS.UPDATE_QUERY_PARAMS_GET_PAYMENT_HISTORY_LIST, { paramsPaymentHistoryList });
    dispatch(ACTIONS.GET_PAYMENT_HISTORY_LIST, { isLoadMore, onFinish });
  },

  async [ACTIONS.CANCEL_MANUAL_PAYMENT]({ commit, state, dispatch }, { batchPaymentId, ...rest }) {
    commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: cancelManualPayment,
        variables: {
          batchPaymentId
        }
      });
      if (!errors) {
        await dispatch(ACTIONS.GET_PAYMENT_HISTORY_LIST, {
          paramsPaymentHistoryList: {
            ...state.paramsPaymentHistoryList,
            offset: 0,
            tenant_id: rest.tenantId,
            customer_id: rest.customerId
          }
        });
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: {
            ...state.paramsListInvoices,
            offset: 0,
            tenantId: rest.tenantId,
            customerId: rest.customerId
          }
        });
        commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_SUCCESS, {
          cancelManualPayment: data.cancelManualPayment
        });
      } else {
        await dispatch(ACTIONS.GET_PAYMENT_HISTORY_LIST, {
          paramsPaymentHistoryList: {
            ...state.paramsPaymentHistoryList,
            offset: 0,
            tenant_id: rest.tenantId,
            customer_id: rest.customerId
          }
        });
        await dispatch(ACTIONS.GET_CUSTOMER_INVOICES, {
          paramsListInvoices: {
            ...state.paramsListInvoices,
            offset: 0,
            tenantId: rest.tenantId,
            customerId: rest.customerId
          }
        });
        commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CANCEL_MANUAL_PAYMENT_FAILED, error);
    } finally {
      commit(MUTATIONS.END_CANCEL_MANUAL_PAYMENT);
    }
  },
  async [ACTIONS.SET_PARAMS_TOP_UP_CREDIT]({ commit }, { paramsTopUpCredit }) {
    commit(MUTATIONS.SET_PARAMS_TOP_UP_CREDIT, { paramsTopUpCredit });
  },
  async [ACTIONS.RESET_PARAMS_TOP_UP_CREDIT]({ commit }) {
    commit(MUTATIONS.RESET_PARAMS_TOP_UP_CREDIT);
  },

  async [ACTIONS.CREATE_TOP_UP_CREDIT]({ commit, state, dispatch }, { paymentProof = [] }) {
    commit(MUTATIONS.CREATE_TOP_UP_CUST_CREDIT_REQUEST);
    try {
      const { data, errors } = await apolloClient.mutate({
        mutation: createTopUpCredit,
        variables: {
          ...state.paramsTopUpCredit,
          paymentProof,
          totalPaidAmount: +state.paramsTopUpCredit.totalPaidAmount
        }
      });
      await dispatch(ACTIONS.GET_XERO_CUSTOMER, {
        tenantId: state.paramsTopUpCredit.tenantId,
        customerId: state.paramsTopUpCredit.customerId
      });
      await dispatch(ACTIONS.GET_PAYMENT_HISTORY_LIST, {
        paramsPaymentHistoryList: {
          ...state.paramsPaymentHistoryList,
          offset: 0,
          tenant_id: state.paramsTopUpCredit.tenantId,
          customer_id: state.paramsTopUpCredit.customerId
        }
      });
      if (!errors) {
        commit(MUTATIONS.CREATE_TOP_UP_CUST_CREDIT_SUCCESS, {
          paymentSummaryTopUp: data.createTopupCustomerCredit
        });
      } else {
        commit(MUTATIONS.CREATE_TOP_UP_CUST_CREDIT_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.CREATE_TOP_UP_CUST_CREDIT_FAILED, error);
    }
  },
  async [ACTIONS.SET_ENTRY_TOP_UP_CREDIT]({ commit }, entryName) {
    commit(MUTATIONS.SET_ENTRY_TOP_UP_CREDIT, entryName);
  },

  async [ACTIONS.GET_PAYMENT_INVOICE_DETAILS]({ commit }, { params }) {
    commit(MUTATIONS.GET_PAYMENT_INVOICE_DETAILS_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: getPaymentHistoryByPaymentId,
        variables: params
      });
      if (!errors) {
        commit(MUTATIONS.GET_PAYMENT_INVOICE_DETAILS_SUCCESS, {
          paymentInvoiceDetails: data.getPaymentHistoryByPaymentId
        });
      } else {
        commit(MUTATIONS.GET_PAYMENT_INVOICE_DETAILS_FAILED, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.GET_PAYMENT_INVOICE_DETAILS_FAILED, error);
    }
  }
};
