import gql from 'graphql-tag';

export const addItemToCart = gql`
  mutation addItemToCart(
    $customer_id: Int!
    $delivery_date: String
    $delivery_time: String
    $description: String
    $po_number: String
    $standalone: Boolean
    $item: AddItemToCartInput!
  ) {
    addItemToCart(
      customer_id: $customer_id
      delivery_date: $delivery_date
      delivery_time: $delivery_time
      description: $description
      po_number: $po_number
      standalone: $standalone
      item: $item
    ) {
      id
      user_id
      customer_id
      delivery_date
      delivery_time
      description
      po_number
      standalone
    }
  }
`;

export const saleGetCartItems = gql`
  query saleGetCartItems($userId: Int!, $customerId: Int!) {
    saleGetCartItems(userId: $userId, customerId: $customerId) {
      delivery_date
      delivery_time
      description
      po_number
      standalone
      items {
        id
        sku_id
        inven_id
        price
        price_is_tax_inclusive
        order_weight
        halal
        increment_qty
        is_favorite
        max_quantity
        oom
        uom
        is_order_by_weight
        is_catch_weight
        is_out_of_stock
        total_available_quantity
        is_back_order
        product_name
        product_type_id
        quantity
        sku
        quotation_status
        selected
        supplier_id
        tax_rate
        tenant_class_id
        tenant_email
        tenant_email_notification
        tenant_id
        tenant_name
        tenant_phone
        unit_amount
        unit_price
        weight
        market_price
        origin_price
        is_sample
        order_qty
        specs
        image
        is_booking_item
        is_unavailable
      }
    }
  }
`;

export const saleChangeCartItem = gql`
  mutation saleChangeCartItem($customerId: Int!, $items: [ChangeCartItemInputType]!) {
    saleChangeCartItem(customerId: $customerId, items: $items)
  }
`;

export const saleRemoveCartItem = gql`
  mutation saleRemoveCartItem($id: String!) {
    saleRemoveCartItem(id: $id)
  }
`;

export const saleCheckClosingTime = gql`
  mutation saleCheckClosingTime($items: [CheckClosingTimeInput]) {
    saleCheckClosingTime(items: $items) {
      skuId
      closingTime
      available
    }
  }
`;
export const checkDuplicateOrder = gql`
  mutation checkDuplicateOrder(
    $customerId: Int!
    $deliveryAddressId: Int!
    $items: [CheckDuplicateOrderTenantInfo]!
  ) {
    checkDuplicateOrder(customerId: $customerId, items: $items, deliveryAddressId: $deliveryAddressId) {
      id
      token
      po_number
      standalone
      delivery_date
      delivery_time
      delivery_address
      billing_address
      description
      tenant_id
      tenant_name
      payment_type
      orderItems {
        id
      }
      order_status_id
    }
  }
`;

export const saleCheckBlackListOrder = gql`
  mutation saleCheckBlackListOrder(
    $customerId: Int!
    $deliveryAddress: String
    $tenantId: Int!
    $cartItems: [AddBlackListOrderItemInput]!
  ) {
    saleCheckBlackListOrder(
      customerId: $customerId
      deliveryAddress: $deliveryAddress
      tenantId: $tenantId
      cartItems: $cartItems
    ) {
      customer_name
      supplier_name
      supplier_phone
    }
  }
`;

export const saleGetLatestPrice = gql`
  query saleGetLatestPrice($skuIds: [Int]!, $accountNumber: String) {
    saleGetLatestPrice(skuIds: $skuIds, accountNumber: $accountNumber) {
      id
      price
      tax_rate
    }
  }
`;

export const saleUpdateLatestPrice = gql`
  mutation saleUpdateLatestPrice($payload: [UpdateLatestPriceInputType]) {
    saleUpdateLatestPrice(payload: $payload)
  }
`;

export const saleUpdateProductSampleCartItem = gql`
  query saleGetCartItems($userId: Int!, $customerId: Int!) {
    saleGetCartItems(userId: $userId, customerId: $customerId) {
      items {
        id
        is_sample
      }
    }
  }
`;
