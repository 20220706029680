<template>
  <ion-page>
    <ion-header no-border class="bar-header">
      <ion-toolbar class="bg-header">
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="home-container">
        <div class="logo-container mt-80">
          <div class="logo">
            <ion-img src="/assets/images/splash_logo.png" alt="Treedots logo" />
          </div>
        </div>
        <Form @submit="onLogin" v-slot="{ meta }">
          <Field
            name="userEmail"
            :label="$t('email_address')"
            :rules="{ required: true, email: true }"
            v-slot="{ field, errorMessage }"
          >
            <ion-item
              lines="none"
              class="box"
              :class="{
                error: errorMessage !== undefined
              }"
            >
              <ion-input
                class="no-border"
                v-bind="field"
                type="email"
                enterkeyhint="done"
                :placeholder="$t('email_address')"
                @keyup.enter="onDoneEmail"
                @ionChange="setShowSignUpInstead(false)"
              />
            </ion-item>
          </Field>
          <div>
            <ion-text color="danger">
              <Error-Message class="error-info" name="userEmail" />
            </ion-text>
            <ion-text v-if="isShowSignUpInstead" class="fw-bold sign-up-instead" @click="goToSignUp">
              {{ $t('sign_up_instead') }}
            </ion-text>
          </div>
          <Field
            name="userPassword"
            :label="$t('password')"
            :rules="{ required: true }"
            v-slot="{ field, errorMessage }"
          >
            <ion-item
              lines="none"
              class="box"
              :class="{
                error: errorMessage !== undefined
              }"
            >
              <ion-input-password>
                <ion-input
                  v-bind="field"
                  id="userPassword"
                  type="password"
                  enterkeyhint="done"
                  @keyup.enter="onDonePwd"
                  :placeholder="$t('password')"
                ></ion-input>
              </ion-input-password>
            </ion-item>
          </Field>
          <Error-Message name="userPassword" class="error p-a" as="div" />
          <div class="text-center">
            <router-link class="forgot-pwd" :to="{ name: 'ForgotPassword' }">{{
              $t('forgot_password')
            }}</router-link>
          </div>
          <div class="lines t-m-2">
            <div class="line-left"></div>
            <ion-text>OR</ion-text>
            <div class="line-right"></div>
          </div>
          <div class="text-center t-m-2">
            <ion-label
              >{{ $t('have_not_set_pwd') }}
              <router-link to="/shared/auth/set-password"
                ><strong>{{ $t('click_here') }}</strong></router-link
              >
            </ion-label>
          </div>
          <div class="button-groups t-m-2">
            <ion-button
              class="home-btn"
              expand="block"
              :disabled="!meta.valid && !isConnectionIssue"
              type="submit"
            >
              <span>{{ $t('log_in') }}</span>
            </ion-button>
            <ion-label class="signup-link"
              >{{ $t('dont_have_an_account') }}
              <router-link to="/shared/auth/signup-info"
                ><strong>{{ $t('sign_up') }}</strong></router-link
              >
            </ion-label>
          </div>
        </Form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import IonInputPassword from '@/components/Shared/IonInputPassword.vue';
import PushNotification from '@/services/shared/helper/push-notification';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { useMutation } from '@vue/apollo-composable';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { loginDashboard } from '../../../../services/shared/graphql';
import { cipher } from '../../../../services/shared/helper/cipher';
export default defineComponent({
  name: 'LoginEmail',
  components: {
    IonInputPassword,
    Form,
    Field,
    ErrorMessage
  },
  setup() {
    const router = useRouter();
    const encrypt = cipher();
    const { mutate: signIn } = useMutation(loginDashboard);
    const storage = inject('$storage');
    const isConnectionIssue = ref(false);
    const isShowSignUpInstead = ref(false);
    const setShowSignUpInstead = (val) => {
      isShowSignUpInstead.value = val;
    };
    const userEmail = ref('');
    const { t } = useI18n();

    const onLogin = async (values, actions) => {
      setShowSignUpInstead(false);
      userEmail.value = values.userEmail;
      try {
        const res = await signIn({
          identity: values.userEmail,
          password: encrypt(values.userPassword)
        });
        const currentUser = res.data.signIn;
        if (currentUser) {
          //store user info into storage
          storage.setUser(currentUser);
          currentUser.language
            ? storage.set('language', currentUser.language)
            : storage.set('language', 'en-US');

          // only allow customer and salesperson access into the app
          PushNotification.subscribeTopic(currentUser.id);
          if (currentUser) router.push({ name: 'Onboarding' });
        } else {
          const error = res.errors;
          if (error[0].message.includes('password')) {
            actions.setFieldError('userPassword', error[0].message);
          } else {
            const isAccountNotFound = error[0].message.includes('User account not found');
            if (isAccountNotFound && !isConnectionIssue.value) {
              setShowSignUpInstead(true);
            }
            actions.setFieldError('userEmail', error[0].message + (isShowSignUpInstead.value ? ' or ' : ''));
          }
        }
      } catch (error) {
        if (error.message.includes('user')) {
          actions.setFieldError('userEmail', error.message);
        } else {
          const isConnectionProblem =
            error.message === t('load_failed') ||
            error.message === t('failed_to_fetch') ||
            error.message.includes('NetworkError');
          isConnectionIssue.value = isConnectionProblem;
          actions.setFieldError(
            'userPassword',
            isConnectionProblem ? t('connection_issue_message') : error.message
          );
        }
      }
    };
    const goToSignUp = () => {
      // use local storage instead state cause this is simple scenario
      localStorage.setItem('newRegisteredEmail', userEmail.value);
      router.push({ name: 'SignUpInfo' });
    };
    const onDoneEmail = () => {
      if (Capacitor.isNativePlatform()) Keyboard.hide();
    };
    const onDonePwd = () => {
      if (Capacitor.isNativePlatform()) Keyboard.hide();
    };
    return {
      onLogin,
      userEmail,
      onDoneEmail,
      onDonePwd,
      isConnectionIssue,
      setShowSignUpInstead,
      isShowSignUpInstead,
      goToSignUp
    };
  }
});
</script>

<style scoped lang="scss">
@import '@/views/Home/home.scss';
@import 'LoginEmail.scss';
.bg-header {
  background: #e6eeef !important;
}
.sign-up-instead {
  color: rgb(0, 106, 255);
  text-decoration: underline;
  font-size: 14px;
}
.error-info {
  font-size: 14px;
}
</style>
