import gql from 'graphql-tag';
import { MeFragment } from './fragment';

export const getCurrentUser = gql`
  mutation {
    me {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const updateProfile = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $gender: String
    $birthDate: String
    $mobile: String
    $email: String
    $tenantId: Int!
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      birthDate: $birthDate
      mobile: $mobile
      email: $email
      tenantId: $tenantId
    )
  }
`;

export const updatePassword = gql`
  mutation updatePassword($userId: Int!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      id
      first_name
      last_name
      mobile
    }
  }
`;

export const updateLanguage = gql`
  mutation updateLanguage($language: String!) {
    updateLanguage(language: $language) {
      language
    }
  }
`;

export const allUserPermissions = gql`
  query {
    allUserPermissions {
      permissions {
        code
      }
    }
  }
`;
