import { DEFAULT_STATE, TYPE_SEARCH } from '@/modules/b2b/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  list: {
    items: []
  },
  moreProductList: {
    items: []
  },
  getProductStatus: '',
  listProduct: '',
  listCache: '',
  item: null,
  isLoadMore: false,
  search: {
    mainCategoryId: '',
    isIncludeTotalCount: true,
    isIncludePurchaseHistory: true,
    hasPrice: false,
    categories: [],
    limit: 10,
    sortBy: '',
    sortType: '',
    searchQueries: '',
    offset: 0,
    isSample: false,
    productTypeId: 1,
    productAttributeValues: []
  },
  searchMore: {
    mainCategoryId: '',
    isIncludeTotalCount: true,
    isIncludePurchaseHistory: false,
    hasPrice: false,
    categories: [],
    limit: 10,
    sortBy: '',
    sortType: '',
    searchQueries: '',
    offset: 0,
    isSample: false,
    productTypeId: 1,
    productAttributeValues: []
  },
  isChangeAllCategory: 1,
  type: TYPE_SEARCH.CATEGORY,
  productIdAfterExplore: null,
  listOosProduct: {
    data: []
  },
  productDetailData: null,
  filterAttributesB2b: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
