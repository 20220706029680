import { apolloClient } from '@/main';
import { saleUpdateProfile } from '@/modules/sale/services/graphql';
import { getCurrentUser } from '@/services/shared/graphql';
import { MUTATIONS } from './mutations';

export const ACTIONS = {
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  SALE_UPDATE_CUSTOMER: 'SALE_UPDATE_CUSTOMER',
  LOG_OUT: 'LOG_OUT'
};

export default {
  async [ACTIONS.GET_CURRENT_USER]({ commit }) {
    commit(MUTATIONS.GET_CURRENT_USER_REQUEST);
    try {
      const { data } = await apolloClient.query({
        query: getCurrentUser
      });
      commit(MUTATIONS.GET_CURRENT_USER_SUCCESS, data.me);
    } catch (error) {
      commit(MUTATIONS.GET_CURRENT_USER_FAILURE, error);
    }
  },

  async [ACTIONS.SALE_UPDATE_CUSTOMER]({ commit, dispatch }, userUpdate) {
    commit(MUTATIONS.SALE_UPDATE_CUSTOMER_REQUEST);
    try {
      const { data, errors } = await apolloClient.query({
        query: saleUpdateProfile,
        variables: userUpdate
      });
      if (data) {
        commit(MUTATIONS.SALE_UPDATE_CUSTOMER_SUCCESS, data);
        dispatch(ACTIONS.GET_CURRENT_USER);
      } else {
        commit(MUTATIONS.SALE_UPDATE_CUSTOMER_FAILURE, errors[0]);
      }
    } catch (error) {
      commit(MUTATIONS.SALE_UPDATE_CUSTOMER_FAILURE, error);
    }
  },

  // reset status error back to default value
  async [ACTIONS.LOG_OUT]({ commit }) {
    commit(MUTATIONS.RESET_STATE, {});
  }
};
