import gql from 'graphql-tag';

export const updateProfile = gql`
  mutation updateUser(
    $firstName: String
    $lastName: String
    $gender: String
    $birthDate: String
    $mobile: String
    $email: String
    $image: String
    $tenantId: Int!
  ) {
    updateUser(
      firstName: $firstName
      lastName: $lastName
      gender: $gender
      birthDate: $birthDate
      mobile: $mobile
      email: $email
      tenantId: $tenantId
      image: $image
    )
  }
`;

export const getPeppolSchemesAndCountries = gql`
  query getPeppolSchemesAndCountries {
    getPeppolSchemesAndCountries {
      countries {
        id
        name
        description
      }
      peppolSchemes {
        id
        name
        code
        description
        country_id
      }
    }
  }
`;

export const getConnectPeppolPage = gql`
  query getConnectPeppolPage($customerId: Int!) {
    getConnectPeppolPage(customerId: $customerId) {
      customerId
      peppolId
      peppolSchemeId
      countryId
    }
  }
`;

export const registerPeppol = gql`
  mutation registerPeppol($scheme: String!, $id: String!) {
    registerPeppol(scheme: $scheme, id: $id)
  }
`;

export const updateCustomerPeppol = gql`
  mutation updateCustomerPeppol($customerId: Int!, $peppolId: String, $peppolSchemeId: Int) {
    updateCustomerPeppol(customerId: $customerId, peppolId: $peppolId, peppolSchemeId: $peppolSchemeId)
  }
`;

export const requestNewProduct = gql`
  mutation requestNewProduct(
    $customerName: String!
    $customerEmail: String
    $supplierName: String
    $productSkuName: String!
    $price: Float
    $remarks: String
  ) {
    requestNewProduct(
      customerName: $customerName
      customerEmail: $customerEmail
      supplierName: $supplierName
      productSkuName: $productSkuName
      price: $price
      remarks: $remarks
    )
  }
`;

export const checkOldPassword = gql`
  query checkOldPassword($password: String!) {
    checkOldPassword(password: $password) {
      id
    }
  }
`;

export const updatePassword = gql`
  mutation updatePassword($userId: Int!, $password: String!) {
    updatePassword(userId: $userId, password: $password) {
      id
      first_name
      last_name
      mobile
    }
  }
`;

export const deleteMyAccount = gql`
  mutation deleteMyAccount {
    deleteMyAccount
  }
`;
