<template>
  <ion-page v-if="!otpMode">
    <ion-header no-border class="bar-header">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content fullscreen="true" class="content">
      <div id="home-container">
        <div class="logo-container">
          <div class="logo">
            <ion-img src="/assets/images/splash_logo.png" alt="Treedots logo" />
          </div>
        </div>
        <Form @submit="onNext">
          <Field
            v-model="phoneNumber"
            name="phoneNumber"
            :label="$t('mobile_number')"
            :rules="{ required: true, phoneValidator: true }"
            v-slot="{ field, errorMessage }"
          >
            <ion-item
              class="box phone-number-group"
              lines="none"
              :class="{
                error: errorMessage !== undefined
              }"
            >
              <vue-tel-input
                v-model="phoneNumber"
                mode="national"
                enabledCountryCode
                enabledFlags
                validCharactersOnly
                ref="phone-field"
                pattern="^[0-9]*$"
                styleClasses="no-border	no-box-shadow "
                disabledFetchingCountry
                :autoDefaultCountry="false"
                :defaultCountry="defaultCountry"
                :autoFormat="false"
                @on-input="onlyNumber"
                @keypress="blockDecimalInput"
                :inputOptions="{
                  type: 'tel',
                  maxlength: 16,
                  placeholder: $t('mobile_number')
                }"
                @country-changed="countryChange"
                :onlyCountries="getPreferedCountryCode()"
              />

              <input v-bind="field" type="tel" maxlength="16" style="display: none" />
            </ion-item>
          </Field>
          <div>
            <ion-text color="danger">
              <Error-Message class="error-info" name="phoneNumber" />
            </ion-text>
            <ion-text v-if="isShowSignUpInstead" class="fw-bold sign-up-instead" @click="goToSignUp">
              {{ $t('sign_up_instead') }}
            </ion-text>
          </div>
          <div class="button-groups t-m-2">
            <ion-button class="home-btn" expand="block" type="submit">
              <span>{{ $t('next') }}</span>
            </ion-button>
            <ion-label class="signup-link"
              >{{ $t('dont_have_an_account') }}
              <router-link to="/shared/auth/signup-info"
                ><strong>{{ $t('sign_up') }}</strong></router-link
              >
            </ion-label>
          </div>
        </Form>
      </div>
    </ion-content>
    <ion-modal :is-open="isOtpSuspend">
      <otp-suspend @cancelOtp="isOtpSuspend = false" />
    </ion-modal>
  </ion-page>
  <Otp
    v-else
    :title="$t('login')"
    :countryCode="countryCode"
    :phoneNumber="phoneNumber"
    :showError="otpError"
    :smsOtp="smsOtp"
    @cancelOtp="
      otpMode = false;
      otpError = false;
    "
    @retryingOtp="otpError = false"
    @onSubmitOtpCode="loginMobileOtp"
    @hiddenError="hiddenError"
    @resendCode="resendCode"
    @otpSuspend="isOtpSuspend = true"
  ></Otp>
</template>

<script>
import Otp from '@/components/Otp';
import OtpSuspend from '@/components/OtpSuspend';
import { getDefaultCountryCode, getPreferedCountryCode } from '@/modules/sale/services/libs/helper';
import { preferredCountryCode } from '@/services/shared/helper/home';
import PushNotification from '@/services/shared/helper/push-notification';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { SmsRetriever } from '@ionic-native/sms-retriever';
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar,
  isPlatform
} from '@ionic/vue';
import { useMutation, useQuery, useResult } from '@vue/apollo-composable';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { computed, defineComponent, inject, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { countries, signInWithOTP, signInWithOTPRequest } from '../../../../services/shared/graphql';

export default defineComponent({
  name: 'LoginMobile',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonBackButton,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    IonImg,
    Field,
    Form,
    ErrorMessage,
    Otp,
    OtpSuspend,
    VueTelInput
  },
  setup() {
    const otpError = ref(false);
    const otpMode = ref(false);
    const isOtpSuspend = ref(false);
    const isShowSignUpInstead = ref(false);
    const isConnectionIssue = ref(false);
    const smsOtp = ref('');
    const router = useRouter();
    const { result } = useQuery(countries);
    const allCountries = useResult(result, null, (data) => data.allCountries);
    const phoneNumber = ref('');
    const countryCode = ref(preferredCountryCode[0]);
    const defaultCountry = ref('');
    const selectedCountry = ref(null);
    const { t } = useI18n();
    const vform = reactive({});
    const fullNumber = computed(() => {
      return parseInt(countryCode.value) + vform.values.phoneNumber;
    });
    const storage = inject('$storage');
    const { mutate: signInWithOTPRequestFn } = useMutation(signInWithOTPRequest);
    const { mutate: signInWithOTPFn } = useMutation(signInWithOTP);
    signInWithOTPRequestFn;
    t;

    const processSMSMessage = (msg) => {
      const pin = msg ? msg.substr(msg.length - 4) : null;
      return /^\d{4}/g.test(pin) ? pin : null;
    };
    const resendCode = async (number) => {
      await signInWithOTPRequestFn({
        phoneNumber: number
      });
    };
    const setShowSignUpInstead = (val) => {
      isShowSignUpInstead.value = val;
    };
    const onNext = async (values, actions) => {
      setShowSignUpInstead(false);
      vform.values = values;
      vform.actions = actions;
      if (!otpMode.value) {
        try {
          const res = await signInWithOTPRequestFn({
            phoneNumber: fullNumber.value
          });
          if (res.errors) {
            const isAccountNotFound = res.errors[0].message.includes('User account not found');
            if (isAccountNotFound && !isConnectionIssue.value) {
              setShowSignUpInstead(true);
            }
            vform.actions.setFieldError(
              'phoneNumber',
              res.errors[0].message + (isShowSignUpInstead.value ? ' or ' : '')
            );
            return;
          }
          const { buyer_type, user_type_id } = res && res.data?.signInWithOTP;
          const isUserMerchantApp =
            ((user_type_id === 6 || user_type_id === 12) && buyer_type === 1) ||
            user_type_id === 7 ||
            user_type_id === 2 ||
            user_type_id === 5;

          if (!isUserMerchantApp) {
            const isUserGroupByApp = (user_type_id === 6 || user_type_id === 12) && buyer_type === 2;
            isUserGroupByApp
              ? vform.actions.setFieldError('phoneNumber', t('login_with_app_instead'))
              : vform.actions.setFieldError('phoneNumber', t('incorrect_phone_number'));
          }

          // start watching SMS. This listener will auto TIMEOUT after 5 mins
          if (isPlatform('android')) {
            SmsRetriever.startWatching()
              .then(async (res) => {
                const msg = processSMSMessage(res.Message);
                smsOtp.value = msg;
              })
              .catch(async (error) => {
                console.log('new message comming: ERROR', error);
              });
          }

          if (isUserMerchantApp) {
            otpMode.value = true;
          }
        } catch (e) {
          if (e.message.includes(`too many`)) {
            isOtpSuspend.value = true;
          } else {
            const isConnectionProblem =
              e.message === t('load_failed') ||
              e.message === t('failed_to_fetch') ||
              e.message.includes('NetworkError');
            isConnectionIssue.value = isConnectionProblem;
            vform.actions.setFieldError(
              'phoneNumber',
              isConnectionProblem ? t('connection_issue_message') : e.message
            );
          }
        }
      }
    };

    const loginMobileOtp = async (otp) => {
      try {
        const res = await signInWithOTPFn({
          phoneNumber: fullNumber.value,
          OTP: otp
        });
        //store user info into storage
        const currentUser = res.data.signInWithOTP;
        storage.setUser(currentUser);
        PushNotification.subscribeTopic(currentUser.id);
        // currentUser.language
        //   ? storage.set('language', currentUser.language)
        //   : storage.set('language', 'en-us');
        currentUser.language
          ? storage.set('language', currentUser.language)
          : storage.set('language', 'en-Us');

        // only allow customer and salesperson access into the app
        if (
          ((currentUser.user_type_id === 6 || currentUser.user_type_id === 12) &&
            currentUser.buyer_type === 1) ||
          currentUser.user_type_id === 7 ||
          currentUser.user_type_id === 2 ||
          currentUser.user_type_id === 5
        ) {
          if (Capacitor.isNativePlatform()) {
            Keyboard.hide();
          }
          router.push({ name: 'Onboarding' });
        } else {
          otpError.value = true;
        }
      } catch (e) {
        if (e.message.includes(`too many`)) {
          if (Capacitor.isNativePlatform()) {
            Keyboard.hide();
          }
          isOtpSuspend.value = true;
        } else {
          otpError.value = true;
        }
      }
    };

    const goToSignUp = () => {
      // use local storage instead state cause this is simple scenario
      localStorage.setItem('newMobileNumber', phoneNumber.value);
      localStorage.setItem('registeredCountryCode', JSON.stringify(selectedCountry.value));
      router.push({ name: 'SignUpInfo' });
    };

    const hiddenError = () => {
      otpError.value = false;
    };
    const phoneMask = '#### ####';

    return {
      otpMode,
      setShowSignUpInstead,
      isShowSignUpInstead,
      countryCode,
      selectedCountry,
      phoneNumber,
      isConnectionIssue,
      preferredCountryCode,
      allCountries,
      onNext,
      phoneMask,
      loginMobileOtp,
      goToSignUp,
      otpError,
      smsOtp,
      hiddenError,
      resendCode,
      getPreferedCountryCode,
      defaultCountry,
      isOtpSuspend
    };
  },
  mounted() {
    this.$refs['phone-field'].choose('SG');
    this.$refs['phone-field'].focus();
  },
  watch: {
    // $route() {
    //   this.$refs['phone-field'].choose('SG');
    // }
  },
  methods: {
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.phoneNumber = input;
      this.setShowSignUpInstead(false);
    },

    blockDecimalInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    countryChange(val) {
      this.defaultCountry = val.iso2;
      this.countryCode = val.dialCode || getDefaultCountryCode();
      this.selectedCountry = val;
      this.$refs['phone-field'].focus();
      //this.setShowSignUpInstead(false);
    }
  }
});
</script>

<style scoped lang="scss">
// organize-imports-ignore
@import '@/views/Home/home.scss';
ion-select::part(icon) {
  background-repeat: no-repeat;
  width: 18px;
}
ion-select {
  margin-left: 10px;
  color: #9ca3af !important;
}
ion-select ~ .phone-number {
  margin-left: 4px;
}
.sign-up-instead {
  color: rgb(0, 106, 255);
  text-decoration: underline;
  font-size: 14px;
}
.error-info {
  font-size: 14px;
}
</style>
