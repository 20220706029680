<template>
  <ion-app ref="ionApp">
    <Suspense>
      <template #default>
        <ion-router-outlet mode="md" id="main" />
      </template>
      <template #fallback>
        <span>{{ $t('loading') }}</span>
      </template>
    </Suspense>
    <Maintenance />
  </ion-app>
</template>

<script>
import { apolloClient } from '@/main';
import { getCustomerDetailBuyer } from '@/modules/b2b/services/graphql';
import { saleGetCustomerDetail } from '@/modules/sale/services/graphql';
import { getUserTenantCurrency } from '@/services/shared/graphql';
import Maintenance from '@/views/Maintenance/index.vue';
import { IonApp, IonRouterOutlet, useBackButton } from '@ionic/vue';
import { defineComponent, inject, provide, ref } from 'vue';
import PushNotification from './services/shared/helper/push-notification';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Maintenance
  },
  setup() {
    // This is added to allow overlays to be appended
    // under the current ion-app.
    const storage = inject('$storage');
    (async () => {
      const user = await storage.getUser();
      const selectedCompany = await storage.getSelectedCompany();
      if (user?.id) {
        await getNewCurrencySymbol(user);
        PushNotification.subscribeTopic(user.id);
        if (selectedCompany?.id) {
          refetchSelectedCompany(user.user_type_id, selectedCompany);
        }
      }
    })();

    // set priority to 9999 to make sure this handler will trigger at the top of propagate events
    useBackButton(9999, async (processNextHandler) => {
      const isDisableNativeBackButton = await storage.getDisableNativeBackButton();
      if (isDisableNativeBackButton !== null && isDisableNativeBackButton === '1') {
        return false;
      }
      processNextHandler();
    });

    const refetchSelectedCompany = async (userId, selectedCompany) => {
      const query = userId === 6 || userId === 12 ? getCustomerDetailBuyer : saleGetCustomerDetail;
      try {
        const { data } = await apolloClient.query({
          query,
          variables: {
            id: selectedCompany.id
          }
        });
        await storage.setSelectedCompany({
          ...selectedCompany,
          account_number:
            userId === 6 || userId === 12
              ? data.getCustomerDetailBuyer.account_number
              : data.saleGetCustomerDetail.account_number,
          persons:
            userId === 6 || userId === 12
              ? data.getCustomerDetailBuyer.persons
              : data.saleGetCustomerDetail.persons
        });
      } catch (e) {
        console.error(e);
      }
    };

    const getNewCurrencySymbol = async (userData) => {
      if (userData?.currency_symbol && userData?.currency_symbol !== 'undefined') {
        return;
      }

      try {
        const { data } = await apolloClient.query({
          query: getUserTenantCurrency
        });
        if (data?.getUserTenantCurrency) {
          const userDataWithNewCurrencySymbol = { ...userData };
          userDataWithNewCurrencySymbol.currency_symbol = data.getUserTenantCurrency;
          storage.setUser(userDataWithNewCurrencySymbol);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const ionApp = ref(null);
    provide('$ionApp', ionApp);

    return {
      ionApp
    };
  }
});
</script>
<style scoped lang="scss">
* {
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  -o-user-select: text !important;
  user-select: text !important;
}
</style>
