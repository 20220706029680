import gql from 'graphql-tag';

export const addItemToCart = gql`
  mutation addItemToCart(
    $customer_id: Int!
    $delivery_date: String
    $delivery_time: String
    $description: String
    $po_number: String
    $standalone: Boolean
    $item: AddItemToCartInput!
  ) {
    addItemToCart(
      customer_id: $customer_id
      delivery_date: $delivery_date
      delivery_time: $delivery_time
      description: $description
      po_number: $po_number
      standalone: $standalone
      item: $item
    ) {
      id
      user_id
      customer_id
      delivery_date
      delivery_time
      description
      po_number
      standalone
    }
  }
`;

export const getB2bCartItems = gql`
  query getB2bCartItems($userId: Int!, $customerId: Int!) {
    getB2bCartItems(userId: $userId, customerId: $customerId) {
      delivery_date
      delivery_time
      description
      po_number
      standalone
      items {
        id
        is_sample
        sku_id
        price
        price_is_tax_inclusive
        order_weight
        halal
        increment_qty
        is_favorite
        max_quantity
        oom
        uom
        is_out_of_stock
        is_back_order
        total_available_quantity
        is_order_by_weight
        is_catch_weight
        product_name
        product_type_id
        quantity
        sku
        quotation_status
        selected
        supplier_id
        tax_rate
        tenant_class_id
        tenant_email
        tenant_email_notification
        tenant_id
        tenant_name
        tenant_phone
        unit_amount
        unit_price
        weight
        market_price
        origin_price
        order_qty
        specs
        image
        is_booking_item
        is_unavailable
      }
    }
  }
`;

export const changeCartItemB2b = gql`
  mutation changeCartItemB2b($customerId: Int!, $items: [ChangeCartItemInputType]!) {
    changeCartItemB2b(customerId: $customerId, items: $items)
  }
`;

export const removeCartItemB2b = gql`
  mutation removeCartItemB2b($id: String!) {
    removeCartItemB2b(id: $id)
  }
`;

export const checkClosingTime = gql`
  mutation checkClosingTime($items: [CheckClosingTimeInput]) {
    checkClosingTime(items: $items) {
      skuId
      closingTime
      available
    }
  }
`;
export const checkDuplicateOrder = gql`
  mutation checkDuplicateOrder(
    $customerId: Int!
    $deliveryAddressId: Int!
    $items: [CheckDuplicateOrderTenantInfo]!
  ) {
    checkDuplicateOrder(customerId: $customerId, items: $items, deliveryAddressId: $deliveryAddressId) {
      id
      token
      po_number
      standalone
      delivery_date
      delivery_time
      delivery_address
      billing_address
      description
      tenant_id
      tenant_name
      payment_type
      orderItems {
        id
      }
      order_status_id
    }
  }
`;
export const checkBlackListOrder = gql`
  mutation checkBlackListOrder(
    $customerId: Int!
    $deliveryAddress: String!
    $tenantId: Int!
    $cartItems: [AddBlackListOrderItemInput]!
  ) {
    checkBlackListOrder(
      customerId: $customerId
      deliveryAddress: $deliveryAddress
      tenantId: $tenantId
      cartItems: $cartItems
    ) {
      customer_name
      supplier_name
      supplier_phone
    }
  }
`;
export const CheckCustomerCuisines = gql`
  query CheckCustomerCuisines($customerId: Int!) {
    checkCustomerCuisines(customerId: $customerId) {
      totalCountOrders
      totalCountCustomerCuisines
      isSkip
      customerCreatedDate
    }
  }
`;
export const AddSkipCustomerCuisine = gql`
  mutation AddSkipCustomerCuisine($customerId: Int!) {
    addSkipCustomerCuisine(customerId: $customerId)
  }
`;

export const getLatestPrice = gql`
  query getLatestPrice($skuIds: [Int]!, $accountNumber: String) {
    getLatestPrice(skuIds: $skuIds, accountNumber: $accountNumber) {
      id
      price
      tax_rate
    }
  }
`;

export const updateLatesPrice = gql`
  mutation updateLatestPrice($payload: [UpdateLatestPriceInputType]) {
    updateLatestPrice(payload: $payload)
  }
`;
