import { DEFAULT_STATE } from '@/modules/b2b/constants';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const state = {
  ...DEFAULT_STATE,
  customer: null,
  joinStatus: null,
  list: {
    data: []
  },
  totalCount: 0,
  params: {
    tagged: true,
    isIncludeTotalCount: true,
    limit: 20,
    offset: 0,
    isLoadMore: false,
    searchQuery: '',
    tenant_id: null
  },
  customerBranch: null,
  customerConnect: null,
  customerUnConnect: null,
  customerView: null,
  customerDetailBuyer: {},
  customerImage: [],
  subBuyers: [],
  groupsAndCountriesAndDays: {},
  customerTenants: [],
  headCompany: {},
  selectedCustomer: null,
  customerProfile: []
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
