import gql from 'graphql-tag';
import { MeFragment } from './fragment';

export const loginDashboard = gql`
  mutation($identity: String!, $password: String!) {
    signIn(identity: $identity, password: $password) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const requestJWT = gql`
  mutation($oldToken: String!) {
    requestJWT(oldToken: $oldToken) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const me = gql`
  mutation {
    me {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const signInWithOTPRequest = gql`
  mutation($phoneNumber: String!) {
    signInWithOTP(phoneNumber: $phoneNumber) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const signInWithOTP = gql`
  mutation($phoneNumber: String!, $OTP: String!) {
    signInWithOTP(phoneNumber: $phoneNumber, OTP: $OTP) {
      ...MeFragment
    }
  }
  ${MeFragment}
`;

export const getUserTenantCurrency = gql`
  mutation GetUserTenantCurrency {
    getUserTenantCurrency
  }
`;
