import { i18n } from '@/main.js';

export const ORDER_STATUS = {
  DELETED: -3,
  PREPARING: -2,
  REFUNDED: 12,
  TOCOLLECT: [-1, 11],
  COLLECTED: 14,
  CANCELLED: 13
};

export const STATUS_CODE_MESSAGE = () => {
  const { t } = i18n.global;
  return [
    {
      key: 'P2020',
      value: t('order.weight_error_out_of_range')
    }
  ];
};

export const PAYMENT_TRANSACTION_STATUS = {
  PENDING: 'pending',
  SUCCEEDED: 'successful',
  FAILED: 'failed'
};

export const DEFAULT_NO_IMAGE = '/assets/images/no_image.png';
export const DEFAULT_NO_IMAGE_FOR_PRODUCT = '/assets/images/new-default-product-img.png';

export const MERCHANT_APP_NAME = 'TreeDots for Merchants';
export const SUPPLIER_APP_NAME = 'Supplier dashboard';
export const VERIFICATION_TYPE = {
  MOBILE_OTP: 'MOBILE_OTP',
  EMAIL_OTP: 'EMAIL_OTP',
  IS_USER_EXISTS: 'IS_USER_EXISTS'
};
