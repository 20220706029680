<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="bg-header">
        <ion-buttons slot="start">
          <ion-back-button @click="previousStep" default-href="/"></ion-back-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content @touchstart="handleScroll($event)" class="content-signup-form">
      <div id="home-container">
        <div class="logo-container">
          <div class="logo">
            <ion-img src="/assets/images/splash_logo.png" alt="Treedots logo" />
          </div>
        </div>
        <div class="groups">
          <Form @submit="onNext" v-slot="{ meta, values }">
            <!-- User type options -->
            <!-- step 1: country field -->
            <div v-if="currentStep === 1">
              <p style="color: black">
                {{ $t('please_select_your_country') }}
              </p>
              <Field
                name="country"
                :label="$t('country')"
                :rules="{ required: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box mt-5"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-label class="select-country hidden">{{ $t('country') }}</ion-label>
                  <ion-radio-group
                    v-model="selectedCountryId"
                    v-bind="field"
                    @ionChange="handleUpdateCountryCode($event)"
                    class="radio-options-container"
                  >
                    <ion-item class="radio-item" v-for="code in defaultCountry" :key="code.id" lines="none">
                      <ion-radio slot="start" :value="code.id"></ion-radio>
                      <ion-label>{{ code.description }}</ion-label>
                    </ion-item>
                  </ion-radio-group>
                </ion-item>
              </Field>
              <Error-Message name="country" class="error" as="div" />
            </div>

            <!-- step 2: account type options -->
            <div v-if="currentStep === 2" style="margin-bottom: 8px">
              <p style="color: black">{{ $t('register_account_type') }}</p>
              <Field name="isB2c" label="is_b2c" :rules="{ required: true }" v-slot="{ field, errorMessage }">
                <ion-item lines="none" class="box mt-5" :class="{ error: errorMessage !== undefined }">
                  <ion-radio-group v-model="isB2c" v-bind="field" class="radio-options-container">
                    <ion-item class="radio-item" lines="none">
                      <ion-radio slot="start" :value="1" />
                      <ion-label>{{ $t('personal') }}</ion-label>
                    </ion-item>
                    <ion-item class="radio-item" lines="none">
                      <ion-radio slot="start" :value="0" />
                      <ion-label style="max-width: 100%">{{ $t('business') }}</ion-label>
                    </ion-item>
                  </ion-radio-group>
                </ion-item>

                <p v-if="isB2c === 1">
                  {{ $t('register_personal') }}
                </p>
                <p v-if="isB2c === 0">
                  {{ $t('register_business') }}
                </p>
              </Field>
              <Error-Message name="isB2c" class="error" as="div" />
            </div>

            <!-- step 3: user details -->
            <div v-if="currentStep === 3" class="form-container">
              <Field
                name="firstName"
                :label="$t('first_name')"
                :rules="{ required: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-input
                    v-bind="field"
                    type="text"
                    :placeholder="`${$t('first_name')}*`"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                </ion-item>
              </Field>
              <Error-Message name="firstName" class="error" as="div" />
              <Field
                name="lastName"
                :label="$t('last_name')"
                :rules="{ required: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-input
                    v-bind="field"
                    type="text"
                    :placeholder="`${$t('last_name')}*`"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                </ion-item>
              </Field>
              <Error-Message name="lastName" class="error" as="div" />
              <Field name="language" :label="$t('language')" v-slot="{ field, errorMessage }">
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-label class="select-country hidden">{{ $t('language') }}</ion-label>
                  <ion-select
                    v-bind="field"
                    :placeholder="$t('language')"
                    v-model="defaultLanguage"
                    class="select-country"
                    :okText="$t('OK')"
                    :cancelText="$t('cancel')"
                  >
                    <ion-select-option v-for="code in defaultLanguages" v-bind:key="code" :value="code.key">{{
                      code.value
                    }}</ion-select-option>
                  </ion-select>
                </ion-item>
              </Field>
              <Error-Message name="language" class="error" as="div">{{
                $t('please_select_language')
              }}</Error-Message>
              <Field
                v-model="phoneNumber"
                name="phoneNumber"
                :label="$t('mobile_number')"
                :rules="{ required: true, phoneValidator: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box phone-number-group"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <vue-tel-input
                    v-model="phoneNumber"
                    mode="national"
                    enabledCountryCode
                    enabledFlags
                    validCharactersOnly
                    ref="phone-field"
                    pattern="[0-9]*$"
                    styleClasses="no-border	no-box-shadow "
                    disabledFetchingCountry
                    :autoDefaultCountry="false"
                    :defaultCountry="defaultCountryCode"
                    :autoFormat="false"
                    @on-input="onlyNumber"
                    @country-changed="countryChange"
                    @keypress="blockDecimalInput"
                    :inputOptions="{
                      type: 'tel',
                      maxlength: 16,
                      placeholder: `${$t('mobile_number')}*`
                    }"
                    :onlyCountries="getPreferedCountryCode()"
                    @open="handleOpenDropdown()"
                    @close="handleCloseDropdown()"
                  />

                  <input v-bind="field" type="tel" maxlength="16" style="display: none" />
                </ion-item>
              </Field>
              <Error-Message name="phoneNumber" class="error" as="div" />
              <Field
                v-model="registerEmail"
                name="userEmail"
                :label="$t('email_address')"
                :rules="{ required: false, email: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined,
                    'z-index': isDropdown
                  }"
                >
                  <ion-input
                    v-bind="field"
                    v-model="registerEmail"
                    type="email"
                    :placeholder="$t('email_address')"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                  <input v-bind="field" type="email" style="display: none" />
                </ion-item>
              </Field>
              <Error-Message name="userEmail" class="error" as="div" />
              <Field
                name="userPassword"
                :label="$t('password')"
                :rules="{ required: true }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined,
                    'z-index': isDropdown
                  }"
                >
                  <ion-input
                    v-bind="field"
                    id="userPassword"
                    :type="passVisibility.type"
                    :placeholder="`${$t('password')}*`"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                  <ion-img @click="passToggle" :src="passVisibility.visibility" />
                </ion-item>
              </Field>
              <Error-Message name="userPassword" class="error" as="div" />
              <Field
                name="confirmPassword"
                :label="$t('password')"
                :rules="{ required: true, is: values.userPassword }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-input
                    v-bind="field"
                    id="confirmPassword"
                    :type="confirmPassVisibility.type"
                    :placeholder="`${$t('confirm_password')}*`"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                  <ion-img @click="confirmPassToggle" :src="confirmPassVisibility.visibility" />
                </ion-item>
                <div class="error" v-if="errorMessage !== undefined">
                  {{ $t('accountPage.confirm_password_does_not_match') }}
                </div>
              </Field>
              <Field
                name="referralCode"
                :label="$t('referral_code')"
                :rules="{ required: false }"
                v-slot="{ field, errorMessage }"
              >
                <ion-item
                  lines="none"
                  class="box no-margin"
                  :class="{
                    error: errorMessage !== undefined
                  }"
                >
                  <ion-input
                    v-bind="field"
                    id="referralCode"
                    type="text"
                    :placeholder="$t('referral_code_placeholder')"
                    @keydown.enter="closeKeyboard"
                  ></ion-input>
                </ion-item>
              </Field>
              <div class="mb-4">
                <ion-label style="font-style: italic"> * {{ $t('mandatory_field') }} </ion-label>
              </div>
            </div>
            <!-- <Error-Message name="confirmPassword" class="error" as="div" /> -->
            <div class="button-groups">
              <!-- <ion-button class="home-btn" expand="block" type="submit" :disabled="!meta.valid"
                ><span>{{ $t('sign_up') }}</span></ion-button
              > -->
              <ion-button
                v-if="currentStep < 3"
                class="home-btn"
                expand="block"
                type="button"
                @click="nextStep"
                :disabled="disableNextStep()"
              >
                {{ $t('next') }}
              </ion-button>
              <ion-button
                v-if="currentStep === 3"
                class="home-btn"
                expand="block"
                type="submit"
                :disabled="!meta.valid || loading"
              >
                <span v-if="!loading">{{ $t('sign_up') }}</span>
                <ion-spinner color="primary" v-if="loading"></ion-spinner>
              </ion-button>
              <ion-label class="signup-link"
                >{{ $t('already_have_an_account') }}
                <router-link to="/home"
                  ><strong>{{ $t('log_in') }}</strong></router-link
                >
              </ion-label>
            </div>
          </Form>
        </div>
      </div>
    </ion-content>
    <ion-modal :is-open="otpMode">
      <Otp
        :title="$t('sign_up')"
        :countryCode="countryCode"
        :phoneNumber="phoneNumber"
        :showError="otpError"
        :smsOtp="smsOtp"
        :email="tempuserEmail"
        :mobile="tempfullNumber"
        @cancelOtp="
          otpMode = false;
          otpError = false;
          isFinalOtp = false;
        "
        @retryingOtp="otpError = false"
        @onSubmitOtpCode="signUpOtp"
        @hiddenError="hiddenError"
        @resendCode="resendCode"
        @otpSuspend="isOtpSuspend = true"
      ></Otp>
    </ion-modal>
    <ion-modal :is-open="isOtpSuspend">
      <otp-suspend
        @cancelOtp="
          isOtpSuspend = false;
          loading = false;
        "
      />
    </ion-modal>

    <Alert :message="message" v-if="isShowAlert" :withAction="true" @closeAlert="closeAlert" />
  </ion-page>
</template>

<script>
import Alert from '@/components/Alert';
import Otp from '@/components/Otp';
import OtpSuspend from '@/components/OtpSuspend';
import { apolloClient } from '@/main';
import { getDefaultCountryCode, getPreferedCountryCode } from '@/modules/sale/services/libs/helper';
import { cipher } from '@/services/shared/helper/cipher';
import { VERIFICATION_TYPE } from '@/services/shared/helper/constants';
import { b2cCountry, defaultCountry, defaultLanguages } from '@/services/shared/helper/countries';
import { preferredCountryCode } from '@/services/shared/helper/home';
import PushNotification from '@/services/shared/helper/push-notification';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { SmsRetriever } from '@ionic-native/sms-retriever';
import { alertController, isPlatform } from '@ionic/vue';
import { useMutation } from '@vue/apollo-composable';
import { ErrorMessage, Field, Form } from 'vee-validate';
import { computed, defineComponent, getCurrentInstance, inject, nextTick, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import { checkUser, loginDashboard, userSignUp } from '../../../../services/shared/graphql';

const PASSWORD_SHOW = '/assets/images/password-show.svg';
const PASSWORD_HIDE = '/assets/images/password-hide.svg';
export default defineComponent({
  name: 'SignUp',
  components: {
    Form,
    Field,
    ErrorMessage,
    Otp,
    VueTelInput,
    Alert,
    OtpSuspend
  },
  ionViewWillLeave() {
    // clear the registered email or mobile number
    localStorage.setItem('newMobileNumber', '');
    localStorage.setItem('registeredCountryCode', null);
    localStorage.setItem('newRegisteredEmail', '');
  },
  data() {
    return {
      isDropdown: false
    };
  },
  setup() {
    const { t } = useI18n();
    const otpError = ref(false);
    const otpMode = ref(false);
    const isFinalOtp = ref(false);
    const smsOtp = ref('');
    const router = useRouter();
    const loading = ref(false);
    const encrypt = cipher();
    const isOtpSuspend = ref(false);
    const storage = inject('$storage');
    const { mutate: signIn } = useMutation(loginDashboard);
    const { mutate: addUser } = useMutation(userSignUp);
    const phoneNumber = ref('');
    const countryCode = ref(preferredCountryCode[0]);
    const defaultCountryCode = ref('');
    const tempfullNumber = ref('');
    const tempuserEmail = ref('');
    const push = ref(true);
    const defaultLanguage = ref(defaultLanguages[0].key);
    const registerEmail = ref('');
    let vform = {};
    let tempVform = {};
    const fullNumber = computed(() => {
      return parseInt(countryCode.value) + phoneNumber.value;
    });
    const message = ref('');
    const isShowAlert = ref(false);
    const selectedCountry = ref(null);
    const { proxy } = getCurrentInstance();
    const isB2c = ref(null);
    const currentStep = ref(1);
    const passVisibility = reactive({
      type: 'password',
      visibility: PASSWORD_HIDE
    });
    const confirmPassVisibility = reactive({
      type: 'password',
      visibility: PASSWORD_HIDE
    });
    const passToggle = () => {
      if (passVisibility.type === 'password') {
        passVisibility.type = 'text';
        passVisibility.visibility = PASSWORD_SHOW;
      } else {
        passVisibility.type = 'password';
        passVisibility.visibility = PASSWORD_HIDE;
      }
    };
    const confirmPassToggle = () => {
      if (confirmPassVisibility.type === 'password') {
        confirmPassVisibility.type = 'text';
        confirmPassVisibility.visibility = PASSWORD_SHOW;
      } else {
        confirmPassVisibility.type = 'password';
        confirmPassVisibility.visibility = PASSWORD_HIDE;
      }
    };
    const processSMSMessage = (msg) => {
      const pin = msg ? msg.substr(msg.length - 4) : null;
      return /^\d{4}/g.test(pin) ? pin : null;
    };
    const resendCode = async (_, verificationType) => {
      push.value = false;
      vform = tempVform;
      const res = await apolloClient.query({
        query: checkUser,
        variables: {
          email: tempuserEmail.value,
          mobile: tempfullNumber.value,
          verificationType: verificationType
        }
      });
      checkUserResult(res, verificationType);
    };
    const hiddenError = () => {
      otpError.value = false;
    };

    const onNext = async (values, actions) => {
      loading.value = true;
      try {
        vform = { values, actions };
        const res = await apolloClient.query({
          query: checkUser,
          variables: {
            email: vform.values.userEmail,
            mobile: fullNumber.value,
            verificationType: VERIFICATION_TYPE.MOBILE_OTP
          }
        });
        tempVform = vform;
        tempuserEmail.value = vform.values.userEmail;
        tempfullNumber.value = fullNumber.value;
        await checkUserResult(res, VERIFICATION_TYPE.MOBILE_OTP);
      } catch (e) {
        if (e.message.includes(`too many`)) {
          isOtpSuspend.value = true;
        } else {
          message.value = e.message;
          isShowAlert.value = true;
        }
      }
    };
    const checkUserResult = async (res, verificationType) => {
      loading.value = false;

      if (res.error) {
        otpError.value = verificationType == VERIFICATION_TYPE.MOBILE_OTP ? true : false;
        return;
      }

      if (res.data.checkUser === null && push.value) {
        const payload = {
          email: vform.values.userEmail?.length > 0 ? vform.values.userEmail : null,
          password: encrypt(vform.values.userPassword),
          mobile: tempfullNumber.value,
          country_id: selectedCountry.value,
          first_name: vform.values.firstName,
          last_name: vform.values.lastName,
          language: vform.values.language || defaultLanguage.value,
          buyer_type: 1,
          user_type_id: 6,
          is_b2c: isB2c.value === 1 ? true : false,
          referral_code: vform.values.referralCode
        };
        if (isFinalOtp.value) {
          await addUser(payload);
          const res = await signIn({
            identity: payload.email || payload.mobile,
            password: payload.password
          });
          const currentUser = res.data.signIn;
          storage.setUser(currentUser);
          PushNotification.subscribeTopic(currentUser.id);
          currentUser.language
            ? storage.set('language', currentUser.language)
            : storage.set('language', 'en-US');

          if (vform.values.isB2c) {
            await storage.setSkippedOnBoarding();
            router.push('/b2b');
          } else {
            router.push({ name: 'Onboarding', params: { userType: res.data.signIn.buyer_type } });
          }
          otpMode.value = false;
          isFinalOtp.value = false;
        } else if (!otpMode.value) {
          // start watching SMS. This listener will auto TIMEOUT after 5 mins
          if (isPlatform('android')) {
            SmsRetriever.startWatching()
              .then(async (res) => {
                const msg = processSMSMessage(res.Message);
                smsOtp.value = msg;
              })
              .catch(async (error) => {
                console.log('new message comming: ERROR', error);
              });
          }
          otpMode.value = true;
          isFinalOtp.value = true;
        }
      } else {
        if (res.data.checkUser?.email === vform.values.userEmail) {
          redirectToLoginAlert(t('email_already_registered_please_login'));
          return;
        }

        if (res.data.checkUser?.mobile === fullNumber.value) {
          redirectToLoginAlert(t('mobile_already_registered_please_login'));
          return;
        }

        if (!otpMode.value && !vform.values.isB2c) {
          otpError.value = true;
        }
      }
    };
    const signUpOtp = async (otp, verificationType) => {
      push.value = true;
      await checkUserResult(otp, verificationType);
    };
    const closeAlert = () => {
      loading.value = false;
      isShowAlert.value = false;
    };

    const closeKeyboard = () => {
      Keyboard.hide();
    };

    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        closeKeyboard();
      }
    };

    const handleUpdateCountryCode = (e) => {
      selectedCountry.value = e.detail.value;

      if (e.detail.value !== 193) {
        isB2c.value = 0;
      }
    };

    const applyCountryToPhoneField = () => {
      selectedCountry.value === 127
        ? proxy.$refs['phone-field'].choose('MY')
        : proxy.selectedCountryId === 96
        ? proxy.$refs['phone-field'].choose('ID')
        : proxy.$refs['phone-field'].choose('SG');
    };

    const disableNextStep = () => {
      if (currentStep.value === 1) {
        return !selectedCountry.value;
      } else if (currentStep.value === 2) {
        return isB2c.value === null || isB2c.value === undefined;
      }
    };

    const previousStep = () => {
      if (currentStep.value > 1 && selectedCountry.value === 193) {
        currentStep.value--;
      } else if (currentStep.value > 1) {
        currentStep.value = 1;
      } else {
        router.replace('/');
      }
    };

    const nextStep = async () => {
      if (currentStep.value < 3 && selectedCountry.value === 193) {
        currentStep.value++;
      } else {
        currentStep.value = 3;
      }

      if (currentStep.value === 3) {
        await nextTick();
        applyCountryToPhoneField();
      }
    };

    const redirectToLoginAlert = async (message) => {
      const alert = await alertController.create({
        mode: 'ios',
        cssClass: 'my-custom-class',
        message,
        buttons: [
          {
            text: t('back')
          },
          {
            text: t('login'),
            handler: () => {
              router.replace('/home');
            }
          }
        ]
      });
      await alert.present();
    };

    return {
      loading,
      countryCode,
      preferredCountryCode,
      phoneNumber,
      onNext,
      registerEmail,
      signUpOtp,
      otpError,
      smsOtp,
      otpMode,
      isFinalOtp,
      resendCode,
      hiddenError,
      tempuserEmail,
      tempfullNumber,
      defaultCountry,
      defaultLanguages,
      getPreferedCountryCode,
      defaultCountryCode,
      checkUserResult,
      message,
      isShowAlert,
      defaultLanguage,
      passVisibility,
      confirmPassVisibility,
      passToggle,
      confirmPassToggle,
      closeAlert,
      isOtpSuspend,
      closeKeyboard,
      handleScroll,
      b2cCountry,
      isB2c,
      currentStep,
      disableNextStep,
      previousStep,
      nextStep,
      handleUpdateCountryCode,
      redirectToLoginAlert
    };
  },
  mounted() {
    const registeredNewEmailOrMobileNumber = localStorage.getItem('newMobileNumber');
    const registeredCountryCode = localStorage.getItem('registeredCountryCode');
    const registeredEmail = localStorage.getItem('newRegisteredEmail');
    if (registeredCountryCode) {
      const selectedCountryCode = JSON.parse(registeredCountryCode);
      this.countryChange(selectedCountryCode);
    }
    this.phoneNumber = registeredNewEmailOrMobileNumber ? registeredNewEmailOrMobileNumber : '';
    this.registerEmail = registeredEmail ? registeredEmail : '';
  },

  methods: {
    onlyNumber(val) {
      // Get the current input value
      let input = val.toString();

      // Remove any leading zeros and decimal point
      input = input.replace(/^0+|^,|^\.+/g, '');

      // Remove any non-numeric characters
      input = input.replace(/[^\d.,]/g, '');
      input = input.replace(/[.,]/g, '');

      this.phoneNumber = input;
    },

    blockDecimalInput(event) {
      // block decimal and only allow number
      let keyCode = event.keyCode ? event.keyCode : event.which;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    countryChange(val) {
      this.defaultCountryCode = val?.iso2;
      this.countryCode = val?.dialCode || getDefaultCountryCode();
    },
    handleOpenDropdown() {
      this.isDropdown = true;
    },
    handleCloseDropdown() {
      this.isDropdown = false;
    }
  }
});
</script>

<style scoped lang="scss">
$login-background: '/assets/images/signup-bg.svg';
@import '@/views/Home/home.scss';
@import 'SignUp.scss';
ion-select {
  color: #9ca3af !important;
  &.select-country {
    width: 100%;
    color: black !important;
    --placeholder-color: #9ca3af !important;
    --placeholder-opacity: 1;
    &::part(icon) {
      position: absolute;
      right: 16px;
    }
  }
}
.bg-header {
  background: #e6eeef !important;
}
.z-index {
  z-index: -1;
}
.radio-item {
  width: 100vw;
}
.no-margin {
  margin-bottom: 0 !important;
}
.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Optional, adds space between fields */
}
</style>
