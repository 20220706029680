import { SUB_TYPE_NOTIFICATIONS } from '@/modules/shared/constants';
import { FCM } from '@capacitor-community/fcm';
import { Capacitor } from '@capacitor/core';
import { LocalNotifications } from '@capacitor/local-notifications';
import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/vue';

const init = async (router) => {
  if (Capacitor.isNativePlatform()) {
    try {
      await registerForPushNotifications(router);
      console.log('echo: FCM Registration succeed!');
    } catch (e) {
      console.log('echo: FCM Registration Error: ' + JSON.stringify(e));
    }
  }
};

const registerForPushNotifications = async (router) => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();

  // only do this for android
  if (isPlatform('android')) {
    await PushNotifications.createChannel({
      id: 'push-notification',
      name: 'Push notification firebase',
      importance: 4
    });
  }

  PushNotifications.addListener('registration', (token) => {
    console.log('echo: FCM Token: ' + JSON.stringify(token));
  });

  PushNotifications.addListener('registrationError', (error) => {
    console.log('echo: FCM Registration Error: ' + JSON.stringify(error));
  });

  PushNotifications.addListener('pushNotificationReceived', async (notification) => {
    if (Capacitor.getPlatform() === 'android') {
      try {
        if (notification.id.indexOf(':') > -1) {
          const deliveriedMessages = await PushNotifications.getDeliveredNotifications();
          const currentNotificationId = notification.id.split(':')[0];
          const currentNotification = deliveriedMessages.notifications.find(
            (noti) => noti.id == currentNotificationId
          );
          if (currentNotification)
            await PushNotifications.removeDeliveredNotifications({ notifications: [currentNotification] });
        }
      } catch (e) {
        console.log('echo: Push notification error', e);
      }
      const notifications = [
        {
          id: new Date().getTime() / 1000,
          title: notification.title || notification.data?.nt,
          body: notification.body || notification.data?.nm,
          data: notification.data || notification.data?.dt
        }
      ];
      LocalNotifications.schedule({
        notifications
      });
    }
  });

  PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
    const routePath = router.currentRoute.value.path.startsWith('/sale/') ? 'sale' : 'b2b';
    const { product_id: productId, order_id: orderId } = JSON.parse(notification.notification.data.payload);
    if (notification.actionId === 'tap') {
      const subType = Number(notification.notification.data.sub_type);
      if (SUB_TYPE_NOTIFICATIONS.QUOTATION.includes(subType)) {
        await router.push(`/${routePath}/notifications/quotations-updates`);
      } else if (SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE.includes(subType)) {
        await router.push(`/${routePath}/notifications/products-updates`);
      } else if (SUB_TYPE_NOTIFICATIONS.PAYMENT.includes(subType)) {
        await router.push(`/${routePath}/notifications/orders-and-invoices`);
      } else if (
        [...SUB_TYPE_NOTIFICATIONS.ORDER, ...SUB_TYPE_NOTIFICATIONS.ORDER_JOURNEY].includes(subType)
      ) {
        router.push(`/${routePath}/order/purchase/order-detail/${orderId}`);
      } else if (SUB_TYPE_NOTIFICATIONS.CUSTOMER_APPROVAL.includes(subType)) {
        await router.push(`/${routePath}/select-customer`);
      } else if (SUB_TYPE_NOTIFICATIONS.PRODUCT_STOCK_UPDATE.includes(subType)) {
        await router.push(`/${routePath}/main/product-detail/${productId}`);
      } else {
        await router.push(`/${routePath}/notifications`);
      }
    }
  });

  LocalNotifications.addListener('localNotificationActionPerformed', async (notification) => {
    const routePath = router.currentRoute.value.path.startsWith('/sale/') ? 'sale' : 'b2b';
    const subType = Number(notification.notification.data.sub_type);
    const { product_id: productId, order_id: orderId } = JSON.parse(notification.notification.data.payload);
    if (SUB_TYPE_NOTIFICATIONS.QUOTATION.includes(subType)) {
      await router.push(`/${routePath}/notifications/quotations-updates`);
    } else if (SUB_TYPE_NOTIFICATIONS.PRICE_UPDATE.includes(subType)) {
      await router.push(`/${routePath}/notifications/products-updates`);
    } else if (SUB_TYPE_NOTIFICATIONS.PAYMENT.includes(subType)) {
      await router.push(`/${routePath}/notifications/orders-and-invoices`);
    } else if ([...SUB_TYPE_NOTIFICATIONS.ORDER, ...SUB_TYPE_NOTIFICATIONS.ORDER_JOURNEY].includes(subType)) {
      router.push(`/${routePath}/order/purchase/order-detail/${orderId}`);
    } else if (SUB_TYPE_NOTIFICATIONS.CUSTOMER_APPROVAL.includes(subType)) {
      await router.push(`/${routePath}/select-customer`);
    } else if (SUB_TYPE_NOTIFICATIONS.PRODUCT_STOCK_UPDATE.includes(subType)) {
      await router.push(`/${routePath}/main/product-detail/${productId}`);
    } else {
      await router.push(`/${routePath}/notifications`);
    }
  });
};

const getFormatTopic = (topic) => {
  const env = process.env.VUE_APP_ENV || 'development';
  return `${topic}-${env}`;
};
const subscribeTopic = (topic) => {
  if (!Capacitor.isNativePlatform()) return;
  if (!topic) return console.log('Topic cannot be empty');

  const formatedTopic = getFormatTopic(topic);
  FCM.subscribeTo({ topic: formatedTopic })
    .then(() => console.log(`FCM: subscribed to topic ${formatedTopic}`))
    .catch((err) => console.error('FCM: error', err));
};

const deleteInstanceId = async () => {
  if (!Capacitor.isNativePlatform()) return;
  FCM.deleteInstance()
    .then(() => {
      console.log(`Token deleted`);
      getToken();
    })
    .catch((err) => console.error(err));
};

const getToken = async () => {
  FCM.getToken()
    .then((r) => {
      console.log(`FCM Token: ${r.token}`);
    })
    .catch((err) => console.error(err));
};

const removeAllListenerAndSubscribe = (topic) => {
  return Promise.allSettled([
    FCM.unsubscribeFrom({ topic: getFormatTopic(topic) }),
    PushNotifications.removeAllListeners()
  ]);
};

const PushNotification = {
  init,
  subscribeTopic,
  deleteInstanceId,
  removeAllListenerAndSubscribe
};

export default PushNotification;
