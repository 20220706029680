import { i18n } from '@/main.js';

const getUserLS = () => JSON.parse(localStorage.getItem('user'));

const getGenderName = (gender) => {
  if (!gender) return '-';
  return gender.toLowerCase() == 'f'
    ? 'Female'
    : gender.toLowerCase() == 'm'
    ? 'Male'
    : gender.toLowerCase() == 'o'
    ? 'Other'
    : '-';
};

const getImageUrl = (imageId, size = 'medium') => {
  return `${process.env.VUE_APP_IMAGES_STORAGE}/${size}/${imageId}.png`;
};

const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};

const isNumeric = (text) => {
  return !isNaN(text);
};

const isCreditCardNumber = (text) => {
  const regex = /\d{4} \d{4} \d{4} \d{3}/g;
  return regex.test(text);
};

const displayPrice = (price, fixed = 2) => {
  return parseFloat(Math.round(Number(price) * 100) / 100).toFixed(fixed);
};

const randomId = () => {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
};

const getPaymentTypes = () => {
  return [
    {
      label: 'Bank Transfer',
      value: 0
    },
    {
      label: 'Collection',
      value: 1
    },
    {
      label: 'Credit Card',
      value: 2
    }
  ];
};

const getCreditPayments = () => {
  return [
    {
      label: 'COD',
      value: 0
    },
    {
      label: '7 Days',
      value: 1
    },
    {
      label: '15 Days',
      value: 2
    },
    {
      label: '30 Days',
      value: 3
    },
    {
      label: 'Others',
      value: 4
    }
  ];
};

const formatTime = (time) => {
  if (time) {
    return time < 10 ? `0${time}` : time;
  }
  return '00';
};

const getTomorrow = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    tomorrow.getFullYear() +
    '-' +
    ('0' + (tomorrow.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + tomorrow.getDate()).slice(-2)
  );
};

const getAddressFormat = (address) => {
  try {
    let result = address.road ? address.road + ', ' : '';

    if (address.building) {
      result += address.building + ', ';
    }

    if (!address.floor_number && address.unit) {
      result += address.unit + ', ';
    } else if (address.floor_number && !address.unit) {
      result += 'Level ' + address.floor_number + ',';
    } else if (address.floor_number && address.unit) {
      result += '#' + address.floor_number + '-' + address.unit + ', ';
    }

    if (address.stall) {
      result += address.stall + ', ';
    }
    if (address.city) {
      result += address.city + ', ';
    }
    if (address.state) {
      result += address.state + ', ';
    }
    if (address.postal_code) {
      result += address.postal_code + ', ';
    }

    return result.slice(0, -2);
  } catch (error) {
    return '';
  }
};

const getPositions = () => {
  const { t } = i18n.global;
  return [
    {
      value: 'Operation',
      label: t('operation_label')
    },
    {
      value: 'Key Contact',
      label: t('key_contact_label')
    },
    {
      value: 'Finance',
      label: t('finance_label')
    }
  ];
};

const getCustomerProfiles = () => {
  return [
    'Bakery',
    'Bar',
    'Bistro',
    'Cafe',
    'Catering',
    'Corporate',
    'Hawkers',
    'Hawkers (High Frequency)',
    'Home Business',
    'Hotel',
    'Manufacturer',
    'Minimart',
    'NPO',
    'Restaurant',
    'Wet Market',
    'Wholesale',
    'Other'
  ];
};

const removeSpecialCharacter = (str) => {
  return !str ? '' : str.replace(/[#,/,!,*,",',{,},[,\]]/gi, '');
};

const getSpecialCharaterRegex = () => {
  return /[#,/,!,*,",',{,},[,\]]/gi;
};

const getRegexEmail = () => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
};

const getPreferedCountryCode = () => {
  const countryCode = ['SG', 'MY', 'PH', 'ID', 'CN'];
  return countryCode;
};

const findObjectByKey = (dataList, key, value) => {
  return dataList.find((v) => v[key] == value);
};

const getDefaultCountryCode = () => {
  return 65;
};

export {
  capitalizeFirstLetter,
  displayPrice,
  findObjectByKey,
  formatTime,
  getAddressFormat,
  getCreditPayments,
  getCustomerProfiles,
  getDefaultCountryCode,
  getGenderName,
  getImageUrl,
  getPaymentTypes,
  getPositions,
  getPreferedCountryCode,
  getRegexEmail,
  getSpecialCharaterRegex,
  getTomorrow,
  getUserLS,
  isCreditCardNumber,
  isNumeric,
  randomId,
  removeSpecialCharacter
};
