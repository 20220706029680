import gql from 'graphql-tag';

export const TenantFragment = gql`
  fragment TenantFragment on Tenant {
    id
    registration_number
    tax_registration_number
    tax_rate
    building_name
    street_name
    unit_number
    email
    first_name
    last_name
    email_notification
    class_id
    default_credit_card_term
    logo
    tenant {
      id
      name
    }
    xeroIntegration {
      xero_tenant_id
    }
  }
`;

export const MeFragment = gql`
  fragment MeFragment on User {
    email
    language
    first_name
    gender
    id
    image
    last_name
    mobile
    stripe_card_id
    stripe_customer_id
    tokenJWT
    token
    email
    is_b2c
    date_created
    birth_date
    buyer_type
    user_type_id
    username
    currency_symbol
    address {
      id
      customer_id
      floor_number
      street_number
      road
      building
      unit
      stall
      city
      state
      postal_code
      latlng
      country_id
      address_type_id
      active
    }
    country {
      id
      name
      description
      currency_code
      currency_symbol
    }
    type {
      id
      name
      description
    }
    tenant {
      ...TenantFragment
    }
  }
  ${TenantFragment}
`;
